import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.h4.attrs({
  className:
    'font-europa font-bold text-nbt-h4 leading-nbt-h4 text-nbt-anthrazit',
})``;

class H4Heading extends React.Component<Props> {
  render() {
    return <Text className={this.props.className}>{this.props.children}</Text>;
  }
}

export default H4Heading;

import React from 'react';
import styled from 'styled-components';
import { H2Heading, Body, Button } from '../elements';

type Props = {
  content: [
    {
      title: string,
      text: string,
      button?:
        {
          text: string,
          link?: string
        }
    }
  ],
};

const Container = styled.div.attrs({
  className: 'bg-nbt-gray px-6 py-10 md:pt-32 lg:pb-32 lg:flex lg:flex-wrap',
})``;

const List = styled.ul.attrs({
  className: 'list-none pl-0 w-full max-w-6xl mx-auto',
})``;

const CTAOutter = styled.li.attrs({
  className: 'md:mb-32 lg:inline-block lg:w-1/2 lg:mb-24',
})``;

const CTAInner = styled.div.attrs({
  className: 'px-8',
})``;

const Title = styled(H2Heading).attrs({
  className: 'text-nbt-anthrazit max-w-sm',
})``;

const TextBody = styled.div.attrs({
  className:
    'font-nimbus text-white text-nbt-anthrazit text-sm leading-tight mb-6 md:mb-10 md:mt-5 max-w-sm my-3 xl:text-lg',
})``;

class CTA2Columns extends React.Component<Props> {
  render() {
    const { content } = this.props;
    return (
      <Container>
        <List>
          {content.map((item, i) => (
            <CTAOutter key={i}>
              <CTAInner>
                <Title>{item.title}</Title>
                <TextBody>
                  <Body>{item.text}</Body>
                </TextBody>
                {item.button && (
                  <Button type="dark" to={item.button.link}>
                    {item.button.text}
                  </Button>
                )}
              </CTAInner>
            </CTAOutter>
          ))}
        </List>
      </Container>
    );
  }
}

export default CTA2Columns;

import React from 'react';
import styled from 'styled-components';
import { Info, H2Heading, Button } from '../elements';

type Props = { items: [{ title: string, textBody: string }] };

type State = {};

const Container = styled.div.attrs({
  className: 'bg-nbt-gray py-20',
})``;

const Row = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto flex flex-col md:flex-row',
})``;

const AllPhases = styled.div.attrs({
  className: 'flex flex-1 justify-center',
})``;

const CurrentPhase = styled.div.attrs({
  className: 'flex flex-1 justify-center',
})``;

/** Milestones */

const Timeline = styled.div.attrs({ className: 'relative' })`
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #3a3f50;
    top: 0;
    bottom: 0;
    left: 30%;
    margin-left: -3px;

    @media (max-width: 768px) {
      left: 20%;
    }
  }
`;

const Item = styled.button.attrs(({active}) => (
  {
    className: `relative text-left px-10 py-5 w-4/6 focus:shadow-outline focus:outline-none ${active ? ' active' : ''}`
  }))`
  @media (max-width: 768px) {
    left: 20%;
  }

  left: 30%;

  &:first-child {
    padding-top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: white;
    border: 1px solid #3a3f50;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
    left: -10px;
  }
  &:not(.active):not(:hover) > * {
    opacity: 0.55;
  }
`;

const ItemTitle = styled.h4.attrs({
  className: 'font-europa font-bold text-nbt-anthrazit text-nbt-h4 leading-nbt-h4 text-left',
})`
&:hover {
  opacity: 1;
}
`;

const BodyText = styled(Info).attrs({ className: 'font-nimbus text-nbt-anthrazit' })``;

/** CTA */

const BannerWrapper = styled.div.attrs({
  className: `w-2/3 flex flex-col items-start justify-center py-20 lg:py-0`,
})``;

const CTATitle = styled(H2Heading).attrs({
  className: `max-w-2xl text-nbt-anthrazit`,
})``;

const Description = styled(Info).attrs({
  className: `font-nimbus my-8 max-w-lg text-nbt-anthrazit`,
})``;

class Milestones extends React.Component<Props, State> {
  static defaultProps = {
    items: [],
  };

  state = { selected: 0 };

  render() {
    const { items } = this.props;
    const { selected } = this.state;

    return (
      <Container>
        <Row>
          <AllPhases>
            <Timeline>
              {items.map(({ title, textBody }, index) => (
                <Item key={title} active={selected === index}
                      onClick={() => this.setState({ selected: index })}
                >
                  <ItemTitle>
                    {title}
                  </ItemTitle>
                  <BodyText>{textBody}</BodyText>
                </Item>
              ))}
            </Timeline>
          </AllPhases>
          <CurrentPhase>
            <BannerWrapper>
              <CTATitle>
                {items[selected] && items[selected].title}
              </CTATitle>
              <Description>
                {items[selected] && items[selected].textBody}
              </Description>
              <Button>
                Visit The Website
              </Button>
            </BannerWrapper>
          </CurrentPhase>
        </Row>
      </Container>
    );
  }
}

export default Milestones;

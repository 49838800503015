import React from 'react';
import styled from 'styled-components';
import Link from '../utils/link';
import OptimizedImage from './optimizedImage';

type Props = {
  portfolio: [{ logo: string, textBody: string, uid: string }],
};

type State = {
  selected: number,
};

const Container = styled.div.attrs({
  className: 'px-6',
})``;

const Wrapper = styled.div.attrs({ className: 'max-w-screen-xl mx-auto' })``;

/* MOBILE : start */

const SliderSmallScreen = styled.div.attrs({
  className: 'mt-6 md:hidden',
})``;

const SlideSmallScreen = styled.div.attrs({
  className: '',
})``;

const RowBorderSmallScreen = styled.div.attrs({
  className: 'mt-2 mb-6 border border-gray-200',
})`
  box-shadow: 0 15px 25px -8px rgba(25, 25, 60, 0.08);
`;

const RowSmallScreen = styled.div.attrs({
  className: 'my-5 flex flex-row',
})``;

const ThumbnailSmallScreen = styled(OptimizedImage).attrs({
  className: 'ml-3 bg-cover rounded-lg w-20 h-20',
})``;

const ColumnSmallScreen = styled.div.attrs({
  className: 'mx-6',
})``;

const TitleSmallScreen = styled.p.attrs({
  className:
    'pb-2 font-europa text-nbt-anthrazit font-bold leading-tight text-2xl',
})``;

const TextBody = styled.p.attrs({
  className: 'font-nimbus text-nbt-anthrazit text-sm leading-tight',
})``;

/* MOBILE : end */

const Slider = styled.div.attrs({
  className: 'justify-center -mx-5 my-10 flex-wrap hidden md:flex',
})``;

const LinkCard = styled(Link).attrs({
  className: 'm-5 bg-nbt-anthrazit blurred-outline relative',
})`
  width: 18em;
  height: 17.5em;
  cursor: pointer;
`;

const InfoContainer = styled.div.attrs({
  className:
    'text-center p-3 text-white text-nbt-info font-nimbus leading-tight tracking-tight absolute bottom-0 w-full',
})`
  opacity: 0;
  transform: translateY(30%);
  transition: 0.5s ease;
  ${LinkCard}:hover & {
    opacity: 1;
    transform: translateY(0px);
  }
`;

const VentureLogo = styled(OptimizedImage).attrs({ className: 'w-full h-full object-cover' })`
  transition: 0.5s ease;
  ${LinkCard}:hover & {
    opacity: 0.2;
  }  
`;

class GridPortfolio extends React.Component<Props, State> {
  render() {
    const { portfolio } = this.props;

    return (
      <Container>
        <Wrapper>
          <Slider>
            {portfolio &&
            portfolio.map(({ logo, textBody, link }, index) => (
              <LinkCard key={index} to={link}>
                <VentureLogo src={logo} />
                <InfoContainer>{textBody}</InfoContainer>
              </LinkCard>
            ))}
          </Slider>
        </Wrapper>
        {/* MOBILE : start */}
        <SliderSmallScreen>
          {portfolio &&
          portfolio.map(({ logo, title, textBody, link }) => (
            <SlideSmallScreen key={title}>
              <RowBorderSmallScreen>
                <Link to={link}>
                  <RowSmallScreen>
                    <ThumbnailSmallScreen src={logo} />
                    <ColumnSmallScreen>
                      <TitleSmallScreen>{title}</TitleSmallScreen>
                      <TextBody>{textBody}</TextBody>
                    </ColumnSmallScreen>
                  </RowSmallScreen>
                </Link>
              </RowBorderSmallScreen>
            </SlideSmallScreen>
          ))}
        </SliderSmallScreen>
        {/* MOBILE :  end */}
      </Container>
    );
  }
}

export default GridPortfolio;

import React from 'react';
import { Body, H2Heading, H4Heading } from '../elements';
import styled from 'styled-components';
import OptimizedImage from './optimizedImage';

const BREAKPOINT_MEDIUM = 768;
const profileImageSizes = {
  xs: 101,
  md: 180,
};

type Props = {
  title?: string,
  subtitle?: string,
  people: [{ name: string, description: string, imageUrl: string }],
};

type State = {};

const Wrapper = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto px-6 pt-8 pb-20 md:text-center',
})``;

const Title = styled.div.attrs({
  className: 'mb-4 md:mb-5 text-nbt-anthrazit',
})``;

const Subtitle = styled.div.attrs({
  className: 'text-nbt-anthrazit',
})``;

const Figures = styled.div.attrs({})`
  &:not(:first-child) {
    margin-top: 45px;
    @media (min-width: ${BREAKPOINT_MEDIUM}px) {
      margin-top: 3.25rem;
    }
  }
`;

const Figure = styled.figure.attrs({
  className: 'flex mb-6 md:inline-block md:text-center md:ml-2 md:mr-2 mb-6',
})`
  height: ${profileImageSizes.xs}px;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    width: 225px;
    height: 286px;
`;

const FigureImage = styled(OptimizedImage).attrs({
  className: 'inline-block rounded-full object-cover mr-4 md:mr-0 md:mb-5',
})`
  height: ${profileImageSizes.xs}px;
  width: ${profileImageSizes.xs}px;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    height: ${profileImageSizes.md}px;
    width: ${profileImageSizes.md}px;
  }
`;

const Caption = styled.figcaption.attrs({
  className: 'md:w-full text-nbt-anthrazit',
})`
  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
  }
`;

const FigureName = styled.h4.attrs({
  className: 'mt-4 md:mt-0 md:mb-2 text-nbt-anthrazit',
})``;

const Description = styled.span.attrs({
  className: 'text-nbt-anthrazit',
})``;

class People extends React.Component<Props, State> {
  render() {
    const title = this.props.title;
    const subtitle = this.props.subtitle;
    const people = this.props.people;
    return (
      <Wrapper>
        {title && (
          <Title>
            <H2Heading>{title}</H2Heading>
          </Title>
        )}
        {subtitle && (
          <Subtitle>
            <Body>{subtitle}</Body>
          </Subtitle>
        )}
        <Figures>
          {people.map((person, i) => (
            <Figure key={i}>
              {person.imageUrl && (
                <FigureImage src={person.imageUrl} alt="Profile image" sizes="(min-width: 640px) 101px, 180px" />
              )}
              <Caption>
                {person.name && (
                  <FigureName>
                    <H4Heading>{person.name}</H4Heading>
                  </FigureName>
                )}
                {person.description && (
                  <Description>
                    <Body>{person.description}</Body>
                  </Description>
                )}
              </Caption>
            </Figure>
          ))}
        </Figures>
      </Wrapper>
    );
  }
}

export default People;

// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { unescape } from 'lodash';
import OptimizedImage from './optimizedImage';

type Props = {
  media: { type: 'image' | 'video', src: string },
  corners: 'rounded' | 'flat',
  height: string,
};

type State = {};

const Container = styled.div.attrs(({ hasImage }) => ({
  className: `flex justify-center bg-nbt-gray  ${
    hasImage ? 'overflow-hidden' : ''
  }`,
}))`
  background-image: linear-gradient(
    180deg,
    #f5f6f7 41%,
    rgba(247, 248, 250, 0) 56%
  );
`;

const Image = styled(OptimizedImage).attrs({
  className: 'object-cover bg-white w-full max-w-6xl mx-0 lg:mx-16',
})`
  box-shadow: 0 23px 33px 0 #e1e3e7;
  height: ${(props) => `${props.imageHeight}px`};
  @media (min-width: 1024px) {
    border-radius: ${(props) =>
      ({ rounded: '27px 27px 0px 0px', flat: '0px' }[props.corners])};
    overflow: hidden;
  }
`;

const VideoMask = styled.div.attrs({
  className: 'w-full max-w-6xl mx-0 lg:mx-16',
})`
  height: ${(props) => props.height};
  @media (min-width: 1024px) {
    border-radius: ${(props) =>
      ({ rounded: '27px', flat: '0px' }[props.corners])};
    overflow: hidden;
  }
`;

const Video = styled.video.attrs({
  className: 'w-full object-cover',
  controls: true,
})`
  height: 100%;
`;

class BannerMask extends React.Component<Props, State> {
  render() {
    const { media, corners, height } = this.props;
    return (
      <Container hasImage={media.type == 'image' ? true : false}>
        {media &&
          {
            image: (
              <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
                <Image src={media.src} imageHeight={height} corners={corners} sizes="(min-width: 1280px) 1152px, 100vw"  />
              </ScrollAnimation>
            ),
            video: (
              <VideoMask corners={corners} height={height}>
                <Video src={unescape(media.src)} />
              </VideoMask>
            ),
          }[media.type]}
      </Container>
    );
  }
}

export default BannerMask;

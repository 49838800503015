// @flow
import React from 'react';
import styled from 'styled-components';
import type { RichText as RichTextType } from '../utils/types';
import { isRichText, sanitizeRichText } from '../utils/types';
import { RichText } from 'prismic-reactjs';
import linkResolver from '../utils/link-resolver';

type Props = {
  header: string,
  textBody: RichTextType,
};

type State = {};

const DistanceToEdge = styled.section.attrs({
  className: 'px-6 pb-12',
})`
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 4rem;
  }
  @media (min-width: 1024px) {
    &:last-of-type {
      .row {
        border-bottom-width: 2px;
      }
    }
  }
`;

const MaxWidth = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto',
})``;

const Row = styled.div.attrs({
  className: 'row py-10 border-gray-600 border-t-2',
})`
  @media (min-width: 1024px) {
    margin-left: 28rem;
  }
`;

const Title = styled.div.attrs({
  className: 'pb-4 text-nbt-red font-bold font-europa text-xl',
})``;

const Content = styled.div.attrs({
  className: 'accent-red text-nbt-anthrazit',
})`
  // Preserve space for empty paragraphs
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
`;

class SubPageTitleBody extends React.Component<Props, State> {
  render() {
    const { header, textBody } = this.props;

    return (
      <DistanceToEdge>
        <MaxWidth>
          <Row>
            <Title>{header}</Title>
            {isRichText(textBody) && (
              <Content>
                {RichText.render(sanitizeRichText(textBody), linkResolver)}
              </Content>
            )}
          </Row>
        </MaxWidth>
      </DistanceToEdge>
    );
  }
}

export default SubPageTitleBody;

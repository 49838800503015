// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, TextBodyLarge, H1Hero } from '../elements';
import { get } from 'lodash';

type Props = {
  background?: 'gray' | 'white',
  title: string,
  description: string,
  buttonDark: {
    title: string,
    link: string,
  },
  buttonLight: {
    title: string,
    link: string,
  },
};

type State = {};

const Container = styled.div.attrs(({ background }) => ({
  className: `py-20 ${background === 'white' ? 'bg-white' : 'bg-nbt-gray'}`,
}))``;

const ContentWrapper = styled.div.attrs({
  className: 'px-8 w-full mx-auto flex flex-col items-start md:items-center',
})``;

const Title = styled(H1Hero).attrs({
  className:
    'text-nbt-anthrazit text-left mb-3 max-w-3xl md:text-center md:px-0 md:mb-6',
})``;

const Subtitle = styled(TextBodyLarge).attrs({
  className:
    'max-w-4xl text-nbt-anthrazit leading-relaxed md:leading-normal md:text-center',
})``;

const Line = styled.div.attrs({
  className: 'w-10 mb-6 bg-nbt-red',
})`
  height: 0.15rem;
`;

const Buttons = styled(ScrollAnimation).attrs({
  animateIn: 'fadeInUp',
  animateOnce: 'true',
  className: 'flex flex-col md:flex-row mt-8 items-center',
})``;

const ButtonDark = styled(Button).attrs({
  className: 'flex items-center justify-center md:mr-5 md:py-4',
})``;

const ButtonOutlined = styled(Button).attrs({
  arrow: 'colorful',
  className:
    'flex items-center justify-center mt-5 mx-0 rounded-full bg-transparent md:ml-6 md:mt-0',
  shadow: true,
  type: 'light-gray',
})``;

class CTAWith2Buttons extends React.Component<Props, State> {
  render() {
    const {
      title,
      description,
      buttonDark,
      buttonLight,
      background,
    } = this.props;

    return (
      <Container background={background}>
        <ContentWrapper>
          <Line />
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <Title>{title}</Title>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <Subtitle>{description}</Subtitle>
          </ScrollAnimation>
          <Buttons>
            <ButtonDark shadow to={get(buttonDark, 'link')}>
              {get(buttonDark, 'text')}
            </ButtonDark>
            <ButtonOutlined to={get(buttonLight, 'link')}>
              {get(buttonLight, 'text')}
            </ButtonOutlined>
          </Buttons>
        </ContentWrapper>
      </Container>
    );
  }
}

export default CTAWith2Buttons;

import React from 'react';
import styled from 'styled-components';
import { FaQuoteLeft } from 'react-icons/fa';
import OptimizedImage from './optimizedImage';

type Props = {
  quotes: { quote: string, picture: string, subtitle: string }[],
};

type State = {};

const Slider = styled.div.attrs({
  className: 'fade-carousel-outer relative mx-auto my-6 md:my-10 lg:my-16 max-w-lg md:max-w-3xl lg:max-w-4xl',
})``;

const Slides = styled.div.attrs({
  className: 'fade-carousel-inner'
})`
${(props) => typeof props.length === 'number' ?
  `width: ${props.length * 100}%;` : ''}
`;

const Slide = styled.div.attrs(({display}) => ({
  className: `fade-carousel-slide w-full relative${display ? ' active' : ''}`,
}))`
${props => `transform: translateX(-${props.index * 100}%);`}`;

const Quote = styled.div.attrs({
  className: 'font-europa  text-nbt-anthrazit my-5 text-nbt-h4 leading-nbt-h4 md:text-nbt-h3 md:leading-nbt-h3',
})``;

const QuoteLeftIcon = styled(FaQuoteLeft).attrs({
  className: 'text-nbt-red mx-auto',
  size: '1.5em',
})``;

const Headshot = styled(OptimizedImage).attrs({
  className: 'w-10 h-10 rounded-full mx-auto object-cover',
})``;

const Subtitle = styled.div.attrs({
  className: 'my-3 max-w-md text-nbt-anthrazit font-nimbus w-2/3 mx-auto',
})``;

const QuoteContainer = styled.div.attrs({
  className: 'flex flex-col justify-center text-center mx-auto',
})``;

class CarouselTestimonial extends React.Component<Props, State> {
  state = {
    currentQuoteIndex: 0,
  };

  componentDidMount() {
    const quotesLength = this.props.quotes.length - 1;
    // eslint-disable-next-line no-undef
    setInterval(() => {
      if (this.state.currentQuoteIndex === quotesLength) {
        this.setState({ currentQuoteIndex: 0 });
      } else {
        this.setState(({ currentQuoteIndex }) => ({
          currentQuoteIndex: currentQuoteIndex + 1,
        }));
      }
    }, 8000);
  }

  render() {
    const { currentQuoteIndex } = this.state;

    const { quotes } = this.props;

    return (
      <Slider>
        <Slides length={quotes.length} currentQuoteIndex={currentQuoteIndex}>
          {quotes.map(({ quote, picture, subtitle }, index) => (
            <Slide key={index} display={currentQuoteIndex === index} index={index}>
              <QuoteContainer>
                <QuoteLeftIcon />
                <Quote>
                  {quote}
                </Quote>
                <Headshot src={picture} sizes="2.5rem" />
                <Subtitle>{subtitle}</Subtitle>
              </QuoteContainer>
            </Slide>
          ))}
        </Slides>
      </Slider>
    );
  }
}

export default CarouselTestimonial;

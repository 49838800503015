import React from 'react';
import styled from 'styled-components';

type Props = {
  className: string,
  children: React.Node,
};

const Styled = styled.div`
  // Preserve space for empty paragraphs
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
`;

class RichText extends React.Component<Props> {
  render() {
    return (
      <Styled className={this.props.className}>{this.props.children}</Styled>
    );
  }
}

export default RichText;

import React from 'react';
import styled from 'styled-components';
import { H4Heading, Body, Button } from '../elements';

type Props = {
  content: [
    {
      title: string,
      text: string,
      button?: {
        text: string,
        link?: string
      }
    },
  ],
};

const Container = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto px-6 py-5 md:pt-10 lg:flex lg:flex-wrap',
})``;

const Title = styled(H4Heading)``;

const GridCell = styled.div.attrs({
  className: 'mb-8 lg:mb-12 lg:w-1/2 lg:px-6',
})``;

const CTAItem = styled.div.attrs({
  className: 'border rounded py-10 pb-12 px-10 h-full lg:py-12 xl:px-20',
})`
  box-sizing: border-box;
`;

const TextBody = styled(Body).attrs({
  className:
    'font-nimbus font-normal text-nbt-info mb-6 md:text-nbt-body md:leading-snug md:mb-10 md:mt-5 max-w-sm my-3 xl:text-lg',
})``;

class CTA2Grid extends React.Component<Props> {
  render() {
    const { content } = this.props;
    return (
      <Container>
        {content &&
          content.map((item, i) => (
            <GridCell key={i}>
              <CTAItem>
                <Title>{item.title}</Title>
                <TextBody>{item.text}</TextBody>
                {item.button && (
                  <Button type="white" to={item.button.link}>
                    {item.button.text}
                  </Button>
                )}
              </CTAItem>
            </GridCell>
          ))}
      </Container>
    );
  }
}

export default CTA2Grid;

// @flow
import React from 'react';
import styled from 'styled-components';
import Link from '../utils/link';
import OptimizedImage from './optimizedImage';

type Props = {
  image: string,
  date: string,
  hasBorder?: boolean,
  link: string,
  textBody?: string,
  title?: string,
};

const ArticleLink = styled(Link).attrs((props) => ({
  className: `card${props.hasBorder ? ' with-border' : ''}`,
}))``;

const Photo = styled(OptimizedImage).attrs({
  className: 'card-image',
})``;

const Date = styled.p.attrs({
  className: 'card-date',
})``;

const Title = styled.p.attrs({
  className: 'card-title',
})``;

const TextBody = styled.p.attrs({
  className:
    'card-text',
})``;

class Card extends React.Component<Props> {
  render() {
    const { date, hasBorder, image, link, textBody, title } = this.props;
    return (
      <ArticleLink to={link} hasBorder={hasBorder}>
        <Photo src={image} />
        <Date>
          {date}
        </Date>
        {title && (
          <Title>
            {title}
          </Title>
        )}
        {textBody && (
          <TextBody>
            {textBody}
          </TextBody>
        )}
      </ArticleLink>
    );
  }
}

export default Card;
export type { Props };

/* IMPORTANT REFACTOR
  This component's naming is not aligned with
  its other use cases (should rename it and
  some of the styled components to something
  more generic)
*/
import React from 'react';
import styled from 'styled-components';
import { times, get } from 'lodash';
import {
  FaTwitter,
  FaFacebook,
  FaLinkedinIn,
  FaHome,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import { H3Heading, Body, Info, Button } from '../elements';
import { unescape } from 'lodash';
import Siema from 'siema';
import Link from '../utils/link';
import SliderDots from '../elements/sliderDots';
import OptimizedImage from './optimizedImage';

const shadowSpread = '25px';

type path = string;

type Props = {
  header?: string,
  ventures: [
    {
      title: string,
      textBody: string,
      button?: {
        link?: string,
        text: string,
      },
      imageBlock: path,
      avatarProfiles?: [{ textInfo: string, textBody: string, image: path }],
      iconGroup: [{ social: string, link: string }],
    }
  ],
};

type State = {
  selected: number,
};

const WrapperBigScreen = styled.div.attrs({ className: 'hidden my-10 mb-20 flex-row lg:flex max-w-screen-xl mx-auto px-6' })`
box-sizing: content-box;
& > * {
  box-sizing: border-box;
}
`;

const Showcase = styled.div.attrs({
  className: 'flex-1 w-1/2 -ml-3 mr-3 -my-3 xl:-ml-6 xl:mr-6 xl:-my-6',
})``;

const Cell = styled.div.attrs({ className: 'float-left w-1/3 p-3 xl:p-6' })`
  flex: 0 0 auto;
`;

const InnerCell = styled.div.attrs({ className: 'h-0 relative' })`
  // Padding trick to maintain aspect ratio
  padding-top: 100%;
`;

const ThumbnailTouchable = styled.button.attrs(({ active }) => ({
  className: `block absolute w-full h-full top-0 cursor-pointer blurred-outline${
    active ? ' active' : ''
  }`,
}))`
  img {
    opacity: .5;
  }
  &.active,
  &:hover {
    img {
      opacity: 1;
    }
  }
`;

const Thumbnail = styled(OptimizedImage).attrs({
  className: `bg-cover`,
})``;

const Details = styled.div.attrs({
  className: 'bg-nbt-anthrazit py-10 px-10 flex flex-col',
})`
  height: auto;
`;

const Header = styled.div.attrs({
  className: 'flex flex-row justify-between items-center',
})``;

const Title = styled(H3Heading).attrs({
  className: 'max-w-2xl text-white mr-10',
})``;

const Divider = styled.div.attrs({
  className: 'hidden lg:block my-5 border-t border-gray-200',
})``;

const Description = styled(Body).attrs({
  className: 'my-10 max-w-lg text-white',
})``;

const SocialIcons = styled.div.attrs({
  className: ' flex flex-row',
})``;

const TwitterIcon = styled(FaTwitter).attrs({
  className: 'my-1 text-white',
  size: '1.375em',
})``;

const FacebookIcon = styled(FaFacebook).attrs({
  className: 'my-1 text-white ml-3',
  size: '1.375em',
})``;

const LinkedinIcon = styled(FaLinkedinIn).attrs({
  className: 'my-1 text-white ml-3',
  size: '1.375em',
})``;

const WebsiteIcon = styled(FaHome).attrs({
  className: 'my-1 text-white ml-3',
  size: '1.375em',
})``;

const OtherIcon = styled(FaExternalLinkAlt).attrs({
  className: 'my-1 text-white ml-3',
  size: '1.375em',
})``;

const People = styled.div.attrs({
  className: 'flex flex-row',
})``;

const Person = styled.div.attrs({
  className: 'flex flex-row items-center mb-12 mr-10',
})``;

const ProfileImageContainer = styled.span.attrs({
  className: 'inline-block h-10 w-10 rounded-full overflow-hidden'
})``;

const ProfileImage = styled(OptimizedImage).attrs({
  className: 'object-fill',
})``;

const PersonDetails = styled.div.attrs({
  className: '',
})``;

const Name = styled(Info).attrs({
  className: 'font-nimbus text-white text-xs ml-3',
})``;

const Role = styled(Info).attrs({
  className: 'font-nimbus text-white text-xs ml-3',
})``;

const ButtonContainer = styled.div.attrs({
  className: 'mt-auto'
})``;

/* MOBILE : start */
const WrapperSmallScreen = styled.div.attrs({
  className: 'lg:hidden mb-10',
})``;

const SliderSmallScreen = styled.div`
padding-bottom: ${shadowSpread};
margin-bottom: -${shadowSpread};
`;

const SlideSmallScreen = styled.div.attrs({
  className: 'w-full h-full flex-shrink-0',
})``;

const RowBorderSmallScreen = styled.div.attrs({
  className: 'mx-6 mt-2 bg-white border border-gray-200',
})`
  box-shadow: 0 15px ${shadowSpread} -8px rgba(25, 25, 60, 0.08);
`;

const RowSmallScreen = styled.div.attrs({
  className: 'my-5 flex flex-row',
})``;

const ColumnSmallScreen = styled.div.attrs({
  className: 'mr-8 ml-5',
})``;

const TitleSmallScreen = styled.p.attrs({
  className:
    'pb-2 font-europa text-nbt-anthrazit font-bold leading-tight text-2xl',
})``;

const TextBody = styled.p.attrs({
  className: 'font-nimbus text-nbt-anthrazit text-sm leading-tight',
})``;

const ThumbnailContainerSmallScreen = styled.div.attrs({
  className: 'ml-3',
})``;

const ThumbnailSmallScreen = styled(OptimizedImage).attrs({
  className: 'bg-cover mt-6 rounded-lg',
})`
  max-width: 5rem;
`;

/* MOBILE : end */

type GridCellProps = {
  onClick: () => void,
  active: boolean,
  venture: { imageBlock: string },
};

class GridCell extends React.Component<GridCellProps> {
  render() {
    const venture = get(this, 'props.venture');
    const active = get(this, 'props.active');
    const onClick = get(this, 'props.onClick');
    return (
      <Cell>
        <InnerCell>
          {venture && (
            <ThumbnailTouchable onClick={onClick} active={active}>
              <Thumbnail src={venture.imageBlock} />
            </ThumbnailTouchable>
          )}
        </InnerCell>
      </Cell>
    );
  }
}

class Ventures extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }

  state = {
    selected: 0,
  };

  componentDidMount() {
    try {
      const carousel = new Siema({
        selector: this.carouselRef.current,
        perPage: 1,
        onChange: () => {
          this.setState({
            selected: carousel.currentSlide,
          });
        },
      });
    } catch (e) {
      /* Otherwise throws error while testing */
      return;
    }
  }

  render() {
    const { selected } = this.state;

    const { ventures } = this.props;

    if (!ventures) return null;

    const { title, textBody, button, avatarProfiles, iconGroup } = ventures[
      selected
      ];

    return (
      <>
        <WrapperBigScreen>
          <Showcase>
            {times(ventures.length, (i) => (
              <GridCell
                key={i}
                venture={ventures[i]}
                active={selected === i}
                onClick={() => this.setState({ selected: i })}
              />
            ))}
          </Showcase>
          <Details>
            <Header>
              <Title>{title}</Title>
              <SocialIcons>
                {iconGroup &&
                iconGroup.map(({ social, link }) => (
                  <Link key={social} to={link}>
                    {
                      {
                        twitter: <TwitterIcon />,
                        facebook: <FacebookIcon />,
                        linkedin: <LinkedinIcon />,
                        website: <WebsiteIcon />,
                        other: <OtherIcon />,
                      }[social]
                    }
                  </Link>
                ))}
              </SocialIcons>
            </Header>
            <Divider />
            <Description>{textBody}</Description>
            <People>
              {avatarProfiles &&
              avatarProfiles.map(({ image, textBody, textInfo }, index) => (
                <Person key={index}>
                  <ProfileImageContainer>
                    <ProfileImage src={image} sizes="2.5rem" />
                  </ProfileImageContainer>
                  <PersonDetails>
                    <Name>{textBody}</Name>
                    <Role>{unescape(textInfo)}</Role>
                  </PersonDetails>
                </Person>
              ))}
            </People>
            {button && (
              <ButtonContainer>
                <Button to={get(button, 'link')} type="colorful">
                  {get(button, 'text')}
                </Button>
              </ButtonContainer>
            )}
          </Details>
        </WrapperBigScreen>
        {/* MOBILE : start */}
        <WrapperSmallScreen>
          <SliderSmallScreen ref={this.carouselRef}>
            {ventures.map(({ imageBlock, title, textBody }) => (
              <SlideSmallScreen key={title}>
                <RowBorderSmallScreen>
                  <Link to={get(button, 'link')}>
                    <RowSmallScreen>
                      <ThumbnailContainerSmallScreen>
                        <ThumbnailSmallScreen src={imageBlock} sizes="2.5rem" />
                      </ThumbnailContainerSmallScreen>
                      <ColumnSmallScreen>
                        <TitleSmallScreen>{title}</TitleSmallScreen>
                        <TextBody>{textBody}</TextBody>
                      </ColumnSmallScreen>
                    </RowSmallScreen>
                  </Link>
                </RowBorderSmallScreen>
              </SlideSmallScreen>
            ))}
          </SliderSmallScreen>
          <SliderDots currentIndex={this.state.selected} numberOfDots={ventures.length} />
        </WrapperSmallScreen>
        {/* MOBILE :  end */}
      </>
    );
  }
}

export default Ventures;

// @flow
import React from 'react';
import styled from 'styled-components';

type Props = {
  title: string,
  description: string,
  buttonText?: string,
  redlinePresent?: Boolean,
};

type State = {};

const Container = styled.div.attrs({ className: 'py-20 bg-nbt-gray' })``;

const ContentWrapper = styled.div.attrs({
  className: 'flex flex-col items-start md:items-center',
})``;

const Title = styled.p.attrs({
  className:
    'text-left mb-3 font-europa font-bold text-nbt-anthrazit leading-tight px-6 text-4xl max-w-md md:text-5xl md:text-center md:px-0',
})``;

const Subtitle = styled.p.attrs({
  className:
    'max-w-2xl mt-3 font-nimbus text-nbt-anthrazit text-left leading-relaxed px-6 text-base md:text-center md:leading-relaxed md:px-0',
})``;

const Line = styled.div.attrs({
  className: 'w-10 ml-6 mb-6 bg-nbt-red md:ml-0',
})`
  height: 0.15rem;
`;

class Intro extends React.Component<Props, State> {
  render() {
    const { title, description, redlinePresent } = this.props;

    return (
      <Container>
        <ContentWrapper>
          {redlinePresent && <Line />}
          <Title>{title}</Title>
          <Subtitle>{description}</Subtitle>
        </ContentWrapper>
      </Container>
    );
  }
}

export default Intro;

import React from 'react';
import styled from 'styled-components';

type Props = {
  src: string,
};

const Mask = styled.div.attrs({
  className: 'relative overflow-hidden m-auto bg-red-500 w-20 h-20 rounded-full',
})``;

const Photo = styled.img.attrs({
  className: 'absolute w-full h-full object-cover'
})``;

const PhotoMask = ({ src }: Props) => <Mask><Photo src={src} /></Mask>;

export default PhotoMask;

// Based on
// https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links

import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Link as GatsbyLink } from 'gatsby';

type Props = {
  activeClassName?: string,
  className?: string,
  children?: React.Node,
  partiallyActive: boolean,
  to: string | { link_type: string },
};

let RouterLink;

// eslint-disable-next-line no-undef
if (process.env.GATSBY_ENVIRONMENT) {
  RouterLink = GatsbyLink;
} else {
  RouterLink = ReactRouterLink;
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
class Link extends React.Component<Props> {
  render() {
    const {
      children,
      activeClassName,
      partiallyActive,
      className,
      to,
      ...other
    } = this.props;

    // Tailor the following test to your environment.
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to);

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      return (
        <RouterLink
          to={to}
          activeClassName={activeClassName}
          className={className}
          partiallyActive={partiallyActive}
          {...other}
        >
          {children}
        </RouterLink>
      );
    }
    return (
      <a href={to} {...other} className={className}>
        {children}
      </a>
    );
  }
}

export default Link;

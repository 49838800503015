import React from 'react';
import styled from 'styled-components';
import { Info, Body } from '../elements';
import CTAColumn from './CTAColumn';

import Link from '../utils/link';

type Items = [
  {
    logo?: string,
    title: string,
    textBody?: string,
    darkButton?: {
      text: string,
      link?: string,
    },
    lightButton?: {
      text: string,
      link?: string,
    },
  }
];

type Props = {
  additionalLink?: {
    text: string,
    link?: string,
  },
  callToAction?: {
    title?: string,
    textBody?: string,
    button?: {
      text: string,
      link?: string,
    },
  },
  htmlElementId?: string,
  items?: Items,
};

const Wrapper = styled.div.attrs({
  className: 'bg-nbt-gray',
})``;

const Row = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:justify-around',
})``;

const List = styled.div.attrs({
  className: 'h-full flex flex-col mx-6 my-6',
})`
  flex: 2;
`;

const CTA = styled(CTAColumn)``;

const CTAWrapper = styled.div`
  flex: 1;
`;

const Item = styled.div.attrs({
  className: `p-5 bg-white justify-between mb-3 flex flex-col items-start lg:items-center lg:flex-row`,
})`
  :last-of-type {
    margin-bottom: 0;
  }
`;

const AdditionalLink = styled(Link).attrs({
  className:
    'flex flex-row items-center text-nbt-anthrazit py-2 bg-white justify-center mt-3 lg:mb-0 hover:underline',
})``;

const CompanyLogo = styled.img.attrs({
  className: 'bg-cover mr-5 rounded-lg',
})`
  width: 3.125rem;
  height: 3.125rem;
`;

const ItemDetails = styled.div.attrs({
  className: 'flex flex-row items-center text-nbt-anthrazit',
})``;

const ItemButtons = styled.div.attrs({ className: 'flex ml-3' })``;

const ButtonLink = styled(Link).attrs({
  className:
    'button dark px-6 py-1 rounded border-nbt-anthrazit inline-block border mr-3 mt-5 lg:my-0',
})``;

const ButtonShare = styled(Link).attrs({
  className:
    'button white bg-white text-nbt-anthrazit px-6 py-1 rounded border-nbt-anthrazit inline-block border mt-5 lg:my-0',
})``;

const ItemText = styled.div.attrs({
  className: 'flex flex-col max-w-sm',
})``;

const FONT_TO_SHOW = 'font-nimbus';

class CTAList extends React.Component<Props> {
  renderCTA(data) {
    return (
      <CTAWrapper>
        <CTA type="dark" title={data['title']}
             description={data['textBody']}
             button={data['button']}
        />
      </CTAWrapper>
    );
  }

  render() {
    const { additionalLink, callToAction, htmlElementId } = this.props;
    const items = this.props.items;
    const emptyList = items && items instanceof Array && items.length === 0;

    if (emptyList) {
      return (
        <Wrapper id={htmlElementId}>
          {this.renderCTA(callToAction)}
        </Wrapper>
      );
    }

    return (
      <Wrapper id={htmlElementId}>
        <Row>
          {items && items instanceof Array && (
            <List>
              {items.map(
                ({ title, textBody, logo, darkButton, lightButton }) => (
                  <Item key={title}>
                    <ItemDetails>
                      {logo && <CompanyLogo src={logo} />}
                      <ItemText>
                        <Body>{title}</Body>
                        {textBody && (
                          <Info className={FONT_TO_SHOW}>{textBody}</Info>
                        )}
                      </ItemText>
                    </ItemDetails>
                    <ItemButtons>
                      {darkButton && (
                        <ButtonLink to={darkButton.link}>
                          {darkButton['text']}
                        </ButtonLink>
                      )}
                      {lightButton && (
                        <ButtonShare to={lightButton.link}>
                          {lightButton['text']}
                        </ButtonShare>
                      )}
                    </ItemButtons>
                  </Item>
                )
              )}
              {additionalLink && additionalLink['text'] && (
                <AdditionalLink to={additionalLink.link}>
                  {additionalLink['text']}
                </AdditionalLink>
              )}
            </List>
          )}

          {callToAction && this.renderCTA(callToAction)}
        </Row>
      </Wrapper>
    );
  }
}

export default CTAList;

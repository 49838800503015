// @flow
import React from 'react';
import Imgix from 'react-imgix';

type Props = {
  src: string,
  noImgix?: boolean,
  sizes?: string,
};
type State = {};

class OptimizedImage extends React.Component<Props, State> {
  static defaultProps = {
    sizes: '100vw', // default we forcing to use
    alt: '',
  };

  render() {
    const { src, noImgix, sizes, ...rest } = this.props;
    // check src if exist
    if (!src) return null;

    // check noImgix status to just render a standard img tag
    // added for future optimization possibilities
    if (noImgix && noImgix === true) return (<img src={src} {...rest} />);

    // our screen size breakdowns, ref: https://tailwindcss.com/docs/breakpoints/#app
    // (min-width: 640px) 100vw, (min-width: 768px) 100vw, (min-width: 1024px) 100vw, (min-width: 1280px) 100vw

    // details of fit parameter can be found here: https://docs.imgix.com/apis/url/size/fit
    return (<Imgix src={src} sizes={sizes} imgixParams={{ fit: 'clip' }} {...rest} />);
  }
}

export default OptimizedImage;

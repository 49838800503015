import React from 'react';
import styled from 'styled-components';
import { Body } from '../elements';
import { TiArrowRight } from 'react-icons/ti';
import Link from '../utils/link';
import OptimizedImage from './optimizedImage';

type Props = {
  content: [
    {
      link: string,
      title?: string,
      text?: string,
      imageUrl?: string,
    }
  ],
};

const Container = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto px-6 py-5 md:pt-10 lg:flex lg:flex-wrap',
})``;

const GridCell = styled.div.attrs({
  className: 'mb-8 lg:mb-12 lg:w-1/2 lg:px-6',
})``;

const CTAItem = styled(Link).attrs({
  className: 'relative block overflow-hidden cursor-pointer border rounded',
})`
  &:hover {
    h2,
    p {
      text-decoration: underline;
    }
    img {
      transform: scale(1.1);
    }
  }
`;

const BackgroundImage = styled(OptimizedImage).attrs({
  className: 'absolute left-0 top-0 w-full h-full object-cover',
})`
  transition: transform 0.3s;
`;

const CTAItemContent = styled.div.attrs({
  className: 'relative p-6',
})`
  background: linear-gradient(170deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  min-height: 17rem;
  & > * {
    text-shadow: 1px 1px 0.5em black;
  }
`;

const Title = styled.h2.attrs({
  className:
    'relative font-europa font-bold text-nbt-h3 leading-nbt-h3 text-white',
})``;

const TextBody = styled(Body).attrs({
  className:
    'relative font-nimbus font-normal text-nbt-info text-white mb-6 md:text-nbt-body md:leading-snug md:mb-10 md:mt-5 max-w-sm my-3 xl:text-lg',
})``;

const Arrow = styled(TiArrowRight).attrs({
  className: 'inline',
  size: '1.7em',
})``;

class GridIndex extends React.Component<Props> {
  static defaultProps = {
    content: [],
  };
  render() {
    const { content } = this.props;
    return (
      <Container>
        {content &&
          content.map((item, i) => (
            <GridCell key={i}>
              <CTAItem to={item.link}>
                {item.imageUrl && <BackgroundImage src={item.imageUrl} />}
                <CTAItemContent>
                  {item.title && <Title>{item.title}</Title>}
                  {item.text && (
                    <TextBody>
                      {item.text}
                      <Arrow />
                    </TextBody>
                  )}
                </CTAItemContent>
              </CTAItem>
            </GridCell>
          ))}
      </Container>
    );
  }
}

export default GridIndex;

// @flow
import React from 'react';
import styled from 'styled-components';
import { H3Heading, Body } from '../elements';
import Link from '../utils/link';

type Props = {
  background: 'white' | 'gray',
  content: [
    {
      title: string,
      textBody: string,
      link?: { linkTxt: string, linkUrl: string },
    },
  ],
};

type State = {};

const RowWrapper = styled.div.attrs(({ background }) => ({
  className: `px-6 py-10 overflow-hidden ${
    background === 'white'
      ? 'bg-nbt-gray lg:bg-white'
      : 'bg-white lg:bg-nbt-gray'
  }`,
}))``;

const RowBorderSmallScreen = styled.div.attrs({
  className:
    'bg-white border border-gray-200 max-w-screen-xl mx-auto lg:border-0 lg:bg-transparent',
})``;

const Row = styled.div.attrs({
  className: 'flex flex-col flex-wrap justify-start lg:-mx-8 lg:flex-row',
})``;

const Column = styled.div.attrs({
  className: 'py-10 px-8 lg:w-1/3',
})`
  box-sizing: border-box;
`;

const Title = styled(H3Heading).attrs({
  className: 'pb-4 text-nbt-anthrazit text-2xl lg:text-3xl lg:pb-0',
})``;

const Divider = styled.div.attrs({
  className: 'hidden my-5 border-t border-gray-300 lg:block',
})``;

const TextBody = styled(Body).attrs({
  className: 'text-nbt-anthrazit',
})``;

const StyledLink = styled(Link).attrs({
  className:
    'mt-8 mb-2 py-1 font-bold font-europa block text-nbt-button-text text-nbt-anthrazit no-underline border-b border-nbt-light-gray inline-block cursor-pointer hover:border-nbt-anthrazit',
})``;

class Block3Column extends React.Component<Props, State> {
  render() {
    const { content, background } = this.props;

    return (
      <RowWrapper background={background}>
        <RowBorderSmallScreen>
          <Row>
            {content &&
              content.map(({ title, textBody, link }, i) => (
                <Column key={i}>
                  <Title>{title}</Title>
                  <Divider />
                  <TextBody>{textBody}</TextBody>
                  {link && link.linkUrl && (
                    <StyledLink to={link.linkUrl}>{link.linkTxt}</StyledLink>
                  )}
                </Column>
              ))}
          </Row>
        </RowBorderSmallScreen>
      </RowWrapper>
    );
  }
}

export default Block3Column;

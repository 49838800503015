import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

type RichTextSections = {
  /* To be filled */
};

type Props = { content: RichTextSections[] };
type State = {};

const Wrapper = styled.div.attrs({
  className: 'px-6 py-4 bg-white',
})``;

const Row = styled.div.attrs({
  className:
    'font-nimbus font-normal text-nbt-anthrazit text-nbt-info leading-nbt-info flex flex-col justify-start max-w-xl mx-auto lg:flex-row ',
})`
  // Preserve space for empty paragraphs
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
`;

class RichTextColumn extends React.Component<Props, State> {
  render() {
    const { content } = this.props;
    return (
      <Wrapper>
        <Row>{RichText.render(content)}</Row>
      </Wrapper>
    );
  }
}

export default RichTextColumn;

import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.h1.attrs({
  className:
    'font-europa font-bold text-nbt-h3 leading-nbt-h3 md:text-nbt-h1 md:leading-nbt-h1',
})``;

const H1Hero = ({ children, className }: Props) => (
  <Text className={className}>{children}</Text>
);

export default H1Hero;

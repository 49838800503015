type RichText = [
  {
    type?: string,
    text: string,
    spans?: [],
  },
];

export function isRichText(text) {
  // Just a good enough check for our immediate purpose
  return text instanceof Array;
}

// Adds default values to every item if not set
export function sanitizeRichText(richText) {
  return richText.map((item) =>
    Object.assign(
      {
        spans: [],
        text: '',
        type: 'paragraph',
      },
      item
    )
  );
}

export type { RichText };

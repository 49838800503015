
    import React from 'react';
    import Footer from '../components/footer';
    import { useStaticQuery, graphql } from 'gatsby';
    
    const FooterContainer = () => {
      const data = useStaticQuery(graphql`
    query {
      footerJson {
         footer {  about address {  type text } contactUs downloadLink downloadLinkTxt links {  item itemLink } newsletterLink newsletterText smallScreenFooterOptions {  item itemLink } socialIcons {  social link } }
      }
    }`);

      const { footer } = data.footerJson;

      if (!footer) return null;

      return (
        <Footer
          {...footer}
        />
      );
    };
    export default FooterContainer;
  
// @flow
import React from 'react';
import { Info } from '../elements';
import styled from 'styled-components';

type Props = {
  sectionName: string
};

const Container = styled.div.attrs({
  className:
    'max-w-screen-xl mx-auto px-6 pt-6',
})`
box-sizing: content-box;
`;

const Text = styled(Info).attrs({
  className: 'text-nbt-anthrazit py-4 text-sm border-b border-nbt-anthrazit',
})``;

class SectionSeparator extends React.Component<Props> {
  render() {
    const {sectionName} =this.props;
    return (
      <Container>
        <Text>{sectionName}</Text>
      </Container>
    )
  }
}

export default SectionSeparator;

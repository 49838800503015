import React from 'react';
import styled from 'styled-components';
import { H4Heading, Body } from '../elements';

type Props = {
  title?: string,
  statements: [{ title?: string, text: string }],
  quotationText: string,
  quotationByline1: string,
  quotationByline2?: string,
};

type ChildrenProps = {
  children: React.Node,
};

const Container = styled.div.attrs({
  className:
    'my-12 px-10 pt-10 pb-6 md:border md:max-w-5xl md:ml-auto md:px-20 md:mr-auto',
})``;

const Heading = ({ children }: ChildrenProps) => (
  <H4Heading className="mb-10">{children}</H4Heading>
);

const TwoColumns = styled.div.attrs({
  className: 'md:flex md:justify-between',
})``;

const Statements = styled.div.attrs({ className: 'md:w-2/5' })``;

const StatementText = styled.p.attrs({
  className: 'font-europa font-normal text-nbt-info mt-3 mb-10 leading-relaxed',
})``;
const Quotation = styled.div.attrs({ className: 'md:w-2/5' })``;

const QuotationText = ({ children }: ChildrenProps) => (
  <Body className="mb-10">{children}</Body>
);

const QuotationByline2 = ({ children }: ChildrenProps) => (
  <Body className="mt-2">{children}</Body>
);

class Testimonial extends React.Component<Props> {
  render() {
    const {
      title,
      statements,
      quotationText,
      quotationByline1,
      quotationByline2,
    } = this.props;
    return (
      <Container>
        {title && <Heading>{title}</Heading>}
        <TwoColumns>
          <Statements>
            {statements.map((statement, index) => (
              <React.Fragment key={'statement' + index}>
                {statement.title && <H4Heading>{statement.title}</H4Heading>}
                {statement.text && (
                  <StatementText>{statement.text}</StatementText>
                )}
              </React.Fragment>
            ))}
          </Statements>
          <Quotation>
            <QuotationText>&ldquo;{quotationText}&rdquo;</QuotationText>
            <Body>{quotationByline1}</Body>
            {quotationByline2 && (
              <QuotationByline2>{quotationByline2}</QuotationByline2>
            )}
          </Quotation>
        </TwoColumns>
      </Container>
    );
  }
}

export default Testimonial;

import React from 'react';
import styled from 'styled-components';
import { Body, RichText } from '../elements';
import { FaChevronDown } from 'react-icons/fa';
import type { RichText as RichTextType } from '../utils/types';
import { isRichText, sanitizeRichText } from '../utils/types';
import { RichText as RichTextComponent } from 'prismic-reactjs';

type Props = {
  className?: string,
  title: string,
  text?: string | RichTextType,
};

const Details = styled.details.attrs({
  className:
    'bg-white text-nbt-anthrazit border rounded shadow-lg md:shadow-none',
})`
  // Rotate the arrow when the element is open
  &[open] summary svg {
    transform: rotate(180deg);
  }
`;

const Summary = styled.summary.attrs({
  className:
    'cursor-pointer list-none px-6 py-6 md:pl-12 md:opacity-75 flex flex-row-reverse items-center blurred-outline',
})`
  // Hide the default arrow on Chrome
  &::-webkit-details-marker {
    display: none;
  }
  &:hover {
    opacity: 1;
  }
`;

const Heading = styled(Body).attrs({ className: 'flex-grow mr-6' })``;

const Arrow = styled(FaChevronDown).attrs({
  className: 'float-right rounded-full',
  size: '1.3em',
})`
  transition: transform 300ms;
`;

const Text = styled(RichText).attrs({
  className:
    'px-6 py-6 font-nimbus font-normal text-nbt-anthrazit text-nbt-info leading-nbt-info md:text-nbt-body md:leading-nbt-body md:pl-12 md:pr-20 md:pb-10',
})``;

class Accordion extends React.Component<Props> {
  render() {
    const textBody = isRichText(this.props.text)
      ? RichTextComponent.render(sanitizeRichText(this.props.text), '')
      : this.props.text || '';
    return (
      <Details className={this.props.className}>
        <Summary>
          <Arrow />
          <Heading>{this.props.title}</Heading>
        </Summary>
        <Text>{textBody}</Text>
      </Details>
    );
  }
}

export default Accordion;

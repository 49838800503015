/**
 * The linkResolver module parses all the links provided by Prismic,
 * so that they can be properly rendered as `href` or `src` attributes
 * in the html elements
 *
 * An important caveat is the parsing of hash-ids in urls: Prismic renders
 * anchor ids as `https://#foobar` which is invalid. Hence, we have
 * an additional `getHashId` utility function that sanitizes the above url
 * to return just the hash, i.e #foobar
 */

import uidToPathname from './uidToPathname';
import { get } from 'lodash';

const getHashId = (url) => {
  if (!url) {
    return url;
  }

  const match = url.match(/#(.+)/);
  if (!match) {
    return url;
  }

  return `#${match[1]}`;
};

const linkResolver = (link) => {

  if (typeof link === 'string') {
    return link;
  }

  if(typeof link === 'object') {
    switch(get(link, 'link_type')) {
      case 'Document':
        if (link.isBroken) {
          return '/404/';
        }

        if(link.uid.toLowerCase() === 'homepage') {
          return '/';
        }

        return `/${uidToPathname(link.uid)}/`;
      case 'Web':
      case 'Any':
      default:
        return getHashId(link.url);
    }
  }

  return '/';
};

export default linkResolver;

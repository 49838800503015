import React from 'react';
import styled from 'styled-components';

type Props = {
  backgroundColor: string,
  height: number,
};

type State = {};

const Wrapper = styled.div.attrs({ className: '' })`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => `${height}px`};
`;

class Divider extends React.Component<Props, State> {
  render() {
    const { backgroundColor, height } = this.props;

    return <Wrapper backgroundColor={backgroundColor} height={height} />;
  }
}

export default Divider;

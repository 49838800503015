// @flow
import React from 'react';
import styled from 'styled-components';
import { H2Heading } from '../elements';
import Link from '../utils/link';
import OptimizedImage from './optimizedImage';

type Props = {
  header: string,
  iconGroup: [{ social: string }],
};

type State = {};

const Wrapper = styled.div.attrs({
  className:
    'max-w-6xl mx-auto px-6 pt-8 md:px-20 md:py-10 md:text-center xl:px-0',
})``;

const BannerWrapper = styled.div.attrs({
  className: `w-full items-center justify-between flex flex-col py-12 lg:px-32`,
})``;

const Header = styled(H2Heading).attrs({
  className: `leading-tight text-center w-full text-white lg:leading-none text-nbt-anthrazit`,
})``;

const Divider = styled.div.attrs({
  className: 'block lg:block my-0 border-t-2 w-full',
})``;

const SocialIcons = styled.div.attrs({
  className: ' block lg:w-full md:inline-block text-center mb-6',
})``;

const Photo = styled(OptimizedImage).attrs({
  className: 'inline-block  m-10 items-center',
})`
  transform: scale(0.9);
  transition: transform 0.3s;
`;

const LogoLink = styled(Link).attrs({
  className: 'cursor-pointer',
})`
  &:hover {
    img {
      transform: scale(1);
    }
  }
`;

class LogoRoll extends React.Component<Props, State> {
  static defaultProps = {
    iconGroup: [],
  };

  // (min-width: 640px) 31.25vw, (min-width: 768px) 26vw, (min-width: 1024px) 19.53vw, (min-width: 1280px) 15.62vw

  render() {
    const { header, iconGroup } = this.props;

    return (
      <Wrapper>
        <BannerWrapper>
          <Header>{header}</Header>
        </BannerWrapper>
        <Divider />
        <SocialIcons>
          {iconGroup.map(({ social, logoUrl }) => (
            <LogoLink key={logoUrl} to={logoUrl}>
              <Photo key={social} sizes="200px" src={social} />
            </LogoLink>
          ))}
        </SocialIcons>
      </Wrapper>
    );
  }
}

export default LogoRoll;

// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from '../elements';
import { get } from 'lodash';
import H2Heading from '../elements/h2Heading';
import { Body } from '../elements';

type Props = {
  type: 'light' | 'dark',
  title: string,
  description?: string,
  button?: {
    text: string,
    link?: string,
  },
};

type State = {};

const Container = styled.div.attrs(({ type }) => ({
  className: `px-6 overflow-hidden py-20 ${
    type === 'light' ? 'bg-nbt-gray' : 'bg-nbt-anthrazit'
  }`,
}))``;

const ContentWrapper = styled.div.attrs({
  className: 'flex flex-col items-start md:items-center',
})``;

const Title = styled(H2Heading).attrs(({ type }) => ({
  className: `text-left mb-6 leading-tight text-4xl max-w-3xl md:text-5xl md:text-center ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  }`,
}))``;

const Subtitle = styled(Body).attrs(({ type }) => ({
  className: `max-w-4xl mb-10 font-nimbus text-left leading-relaxed md:text-center md:leading-relaxed ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  }`,
}))``;

const Line = styled.div.attrs({
  className: 'w-10 mb-6 bg-nbt-red h-red-bar',
})``;

class CTAColumn extends React.Component<Props, State> {
  render() {
    const { type, title, description, button } = this.props;

    return (
      <ScrollAnimation animateIn="fadeIn" animateOnce="true">
        <Container type={type}>
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <ContentWrapper>
              <Line />

              <Title type={type}>{title}</Title>
              {description && <Subtitle type={type}>{description}</Subtitle>}
              {get(button, 'text') && (
                <Button
                  to={get(button, 'link')}
                  shadow
                  type={type === 'light' ? 'dark' : 'colorful'}
                >
                  {get(button, 'text')}
                </Button>
              )}
            </ContentWrapper>
          </ScrollAnimation>
        </Container>
      </ScrollAnimation>
    );
  }
}

export default CTAColumn;

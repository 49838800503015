import React from 'react';
import styled from 'styled-components';
import { FiAlignRight } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { TiArrowRight, TiArrowLeft } from 'react-icons/ti';
import Link from '../utils/link';
import OptimizedImage from './optimizedImage';

type Props = {
  pictogram: string,
  logo: string,
  menu?: [{ item: string, itemLink?: string }],
  moreMenu?: [{ item: string, itemLink?: string }],
  onToggleMenu: () => void,
  showMenu: boolean,
};

type State = {};

const Menus = styled.div.attrs({
  className: 'main-nav-menus',
})``;

const MainNav = styled.nav.attrs({
  className: 'main-nav',
})``;

const Links = styled.div.attrs({
  className:
    'main-nav-links',
})``;

const PictogramImg = styled(OptimizedImage).attrs({
  className: 'mr-6 w-8 h-8 lg:mr-0',
})``;

const PictogramTextImg = styled(OptimizedImage).attrs({
  className: 'w-24 block lg:hidden',
})``;

const LinkMenuItem = styled(Link).attrs({
  className:
    'main-nav-link flat-outline',
})``;

const LinkHome = styled(Link).attrs({
  className:
    'main-nav-link link-home',
  exact: true,
  partiallyActive: false,
})``;

const LinkHomeSmall = styled(Link).attrs({
  className: 'link-home-sm',
})``;

const LinkMore = styled(Link).attrs({
  className:
    'hidden text-nbt-anthrazit-light px-5 py-3 lg:block hover:bg-nbt-gray hover:text-nbt-anthrazit',
})``;

const ButtonWrapper = styled.div.attrs({
  className: 'hidden lg:block ml-auto',
})``;

const MoreButton = styled.button.attrs({
  className: 'button more-button white',
})``;

const MoreButtonArrowsContainer = styled.div.attrs(({expanded}) => ({
  className: `more-button-arrows-container ${ expanded ? 'expanded' : 'collapsed' }`
}))``;

const MoreButtonArrowWrapper = styled.span.attrs({
  className: 'more-button-arrow-wrapper'
})``;

const RedRightArrow = styled(TiArrowRight).attrs({
  className: 'more-button-arrow more-button-arrow-right',
  size: '1.7em',
})``;

const RedLeftArrow = styled(TiArrowLeft).attrs({
  className: 'more-button-arrow more-button-arrow-left',
  size: '1.7em',
})``;

const MenuIconContainer = styled.div.attrs({
  className: 'side-menu-toggle-link',
})``;

const AlignRightIcon = styled(FiAlignRight).attrs({
  className: 'my-1 text-nbt-anthrazit text-right',
  size: '2.0em',
})``;

const DropdownContent = styled.div.attrs(({ show }) => ({
  className: `menu-dropdown-content ${
    show ? 'block' : 'hidden'
  }`,
}))``;

/* Small screen */

const MenuSmallScreen = styled.nav.attrs({
  className: `lg:hidden fixed right-0 top-0 h-screen w-0 z-10`,
  size: '2.0em',
})``;

const CloseIconSmallScreen = styled(MdClose).attrs((props) => ({
  className: `close-nav-menu-icon-sm ${props.show ? 'inline' : 'hidden'}`,
  size: '2.0em',
}))``;

const LinksSmallScreen = styled.div.attrs((props) => ({
  className: `main-nav-links-sm ${props.show ? 'in' : 'out'}`,
}))``;

const LinkSmallScreen = styled(Link).attrs({
  className: 'main-nav-link-sm',
})``;

class Menu extends React.Component<Props, State> {
  state = {
    showMoreLinks: false,
  };

  onButtonClick() {
    if (typeof this.props.onToggleMenu === 'function') {
      this.props.onToggleMenu();
    }
  }

  render() {
    const { pictogram, logo, menu, moreMenu } = this.props;

    const { showMoreLinks } = this.state;

    return (
      <Menus>
        <MainNav>
          <Links>
            <LinkHome to="/">
              <PictogramImg src={pictogram} sizes="32px" />
            </LinkHome>
            <LinkHomeSmall to="/">
              <PictogramImg src={pictogram} sizes="32px" />
              <PictogramTextImg src={logo} sizes="96px" />
            </LinkHomeSmall>
            {menu.slice(1).map(({ item, itemLink }, index) => (
              <LinkMenuItem
                key={index}
                to={itemLink}
                activeClassName="active"
              >
                {item}
              </LinkMenuItem>
            ))}
            {moreMenu && (
              <ButtonWrapper>
                <MoreButton
                  onClick={() =>
                    this.setState(({ showMoreLinks }) => ({
                      showMoreLinks: !showMoreLinks,
                    }))
                  }
                >
                  <MoreButtonArrowsContainer expanded={this.state.showMoreLinks}>
                    <MoreButtonArrowWrapper>
                      <RedLeftArrow />
                    </MoreButtonArrowWrapper>
                    <MoreButtonArrowWrapper>
                      More <RedRightArrow />
                    </MoreButtonArrowWrapper>
                  </MoreButtonArrowsContainer>
                </MoreButton>
                <DropdownContent show={showMoreLinks}>
                  {moreMenu.map(({ item, itemLink }, index) => (
                    <LinkMore
                      key={index}
                      to={itemLink}
                      activeClassName="active"
                    >
                      {item}
                    </LinkMore>
                  ))}
                </DropdownContent>
              </ButtonWrapper>
            )}
            <MenuIconContainer>
              <AlignRightIcon onClick={() => this.onButtonClick()} />
            </MenuIconContainer>
          </Links>
        </MainNav>
        <MenuSmallScreen>
          <LinksSmallScreen show={this.props.showMenu}>
            {menu.map(({ item, itemLink }) => (
              <LinkSmallScreen
                key={item}
                to={itemLink}
                activeClassName="active"
              >
                {item}
              </LinkSmallScreen>
            ))}
            {moreMenu &&
              moreMenu.map(({ item, itemLink }) => (
                <LinkSmallScreen
                  key={item}
                  to={itemLink}
                  activeClassName="active"
                >
                  {item}
                </LinkSmallScreen>
              ))}
          </LinksSmallScreen>
          <CloseIconSmallScreen
            show={this.props.showMenu}
            onClick={() => this.onButtonClick()}
          />
        </MenuSmallScreen>
      </Menus>
    );
  }
}

export default Menu;

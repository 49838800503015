import React from 'react';
import { Helmet } from 'react-helmet';
import FooterContainer from './footer';
import MenuContainer from './menu';

type Props = {
  children: React.Node,
};

type State = {
  showMenu?: boolean,
};

class PageLayout extends React.Component<Props, State> {
  state = {
    showMenu: undefined,
  };

  constructor(props) {
    super(props);
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu() {
    this.setState({ showMenu: !this.state.showMenu });
  }

  render() {
    if(typeof document !== 'undefined') {
      const preventScrollClass = 'overflow-hidden';
      if(this.state.showMenu) {
        document.body.classList.add(preventScrollClass);
      } else {
        document.body.classList.remove(preventScrollClass);
      }
    }
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Next Big Thing</title>
          <meta
            name="Description"
            content="Co-Founding Europe's Digital Future"
          />
        </Helmet>
        <MenuContainer
          onToggleMenu={this.onToggleMenu}
          showMenu={this.state.showMenu}
        />
        <main>
          {this.props.children}
        </main>
        <FooterContainer />
      </>
    );
  }
}

export default PageLayout;

// @flow
import React from 'react';
import styled from 'styled-components';
import { H2Heading, Body } from '../elements';

type Props = {
  content: [{ title: string, textBody: string }],
};

type State = {};

const RowsWrapper = styled.p.attrs({
  className: 'my-0 lg:my-16 px-6 py-16 lg:py-5 lg:bg-white',
})``;

const RowsBorderSmallScreen = styled.div.attrs({
  className: 'border border-gray-200 lg:border-0 max-w-screen-xl mx-auto',
})`
  @media (min-width: 1024px) {
    &:last-child {
      border-bottom-width: 1px;
      border-color: #485059;
    }
  }
`;

const Row = styled.div.attrs({
  className:
    'flex flex-col items-start bg-white py-10 px-8 border-gray-600 lg:flex-row justify-between lg:items-center lg:border-t lg:px-0',
})``;

const Title = styled.div.attrs({
  className: 'max-w-2xl pb-4 text-nbt-anthrazit lg:pb-0 lg:mr-10',
})``;

const Content = styled.div.attrs({
  className: 'text-nbt-anthrazit max-w-2xl',
})``;

class TableRow extends React.Component<Props, State> {
  render() {
    const { content } = this.props;

    return (
      <RowsWrapper>
        <RowsBorderSmallScreen>
          {content &&
            content.map(({ title, textBody }) => (
              <Row key={title}>
                <Title>
                  <H2Heading>{title}</H2Heading>
                </Title>
                <Content>
                  <Body>{textBody}</Body>
                </Content>
              </Row>
            ))}
        </RowsBorderSmallScreen>
      </RowsWrapper>
    );
  }
}

export default TableRow;

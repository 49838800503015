import React from 'react';
import styled from 'styled-components';
import { H2Heading, Body } from '../elements';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Siema from 'siema';
import SliderDots from '../elements/sliderDots';
import OptimizedImage from './optimizedImage';

type Props = {
  title?: string,
  subtitle?: string,
  images?: string[],
};

type State = {
  currentSlide: number,
};

const Container = styled.div.attrs({
  className: 'px-6 my-8 md:my-12',
})``;

const Wrapper = styled.div.attrs({
  className: 'mx-auto mt-10 max-w-screen-xl',
})``;

const Header = styled.div.attrs({
  className: 'flex flex-col items-center pb-12',
})``;

const RowWrapper = styled.div.attrs({
  className: 'flex flex-col lg:flex-row justify-center lg:items-center',
})``;

const ColumnCenter = styled.div.attrs({
  className: 'flex flex-1 justify-center',
})``;

const Picture = styled(OptimizedImage).attrs({
  className: 'w-full object-cover',
})`
  height: 30rem;
`;
const Title = styled(H2Heading).attrs({
  className: 'text-nbt-anthrazit w-2/3 text-center',
})``;

const SubTitle = styled(Body).attrs({
  className: 'text-nbt-anthrazit lg:mt-5 w-2/3 mt-3 text-center',
})``;

const ArrowLeftTouchable = styled.div.attrs({
  className: 'hidden lg:block mr-16',
})``;

const ArrowRightTouchable = styled.div.attrs({
  className: 'hidden lg:block ml-16',
})``;

const ChevronContainer = styled.button.attrs({
  className: 'bg-nbt-anthrazit p-4 rounded-full hover:bg-gray-700',
})``;

const ChevronLeftIcon = styled(FaChevronLeft).attrs({
  className: 'text-white',
  size: '1em',
})``;

const ChevronRightIcon = styled(FaChevronRight).attrs({
  className: 'text-white',
  size: '1em',
})``;

const Slider = styled.div.attrs({
  className: 'lg:w-10/12'
})``;

class Carousel extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }

  state = {
    currentSlide: 0,
  };

  componentDidMount() {
    try {
      // eslint-disable-next-line no-undef
      this.siema = new Siema({
        selector: this.carouselRef.current,
        perPage: 1,
        onChange: () => {
          this.setState({ currentSlide: this.siema.currentSlide });
        },
      });
    } catch (e) {
      /* Otherwise throws error while testing */
      return;
    }
  }

  render() {
    const { images, title, subtitle } = this.props;

    const { currentSlide } = this.state;

    return (
      <Container>
        <Wrapper>
          {title && subtitle && (
            <Header>
              <Title>{title}</Title>
              <SubTitle>{subtitle}</SubTitle>
            </Header>
          )}
          <div>
            <RowWrapper>
              <ArrowLeftTouchable onClick={() => this.siema.prev()}>
                <ChevronContainer>
                  <ChevronLeftIcon />
                </ChevronContainer>
              </ArrowLeftTouchable>
              <Slider ref={this.carouselRef}>
                {images &&
                  images.map((image, index) => (
                    <ColumnCenter key={index}>
                      <Picture src={image} />
                    </ColumnCenter>
                  ))}
              </Slider>
              <ArrowRightTouchable onClick={() => this.siema.next()}>
                <ChevronContainer>
                  <ChevronRightIcon />
                </ChevronContainer>
              </ArrowRightTouchable>
            </RowWrapper>
            <SliderDots
              currentIndex={currentSlide}
              numberOfDots={images.length}
            />
          </div>
        </Wrapper>
      </Container>
    );
  }
}

export default Carousel;

// @flow
import React from 'react';
import styled from 'styled-components';
import { H1Hero, H2Heading, Body } from '../elements';

type Props = {
  background?: 'white' | 'gray',
  type: 'big' | 'small',
  title: string,
  subtitle?: string,
  showRedLine: boolean,
};

type State = {};

const Container = styled.div.attrs(({ background }) => ({
  className: `py-5 ${background === 'white' ? 'bg-white' : 'bg-nbt-gray'}`,
}))``;

const ContentWrapper = styled.div.attrs({
  className: 'flex flex-col items-start md:items-center',
})``;

const Title = styled.p.attrs({
  className: 'text-left pb-3 max-w-xl px-6 md:text-center text-nbt-anthrazit',
})``;

const Subtitle = styled.p.attrs({
  className:
    'font-europa max-w-2xl mt-3 px-6 text-left md:text-center text-nbt-anthrazit',
})``;

const Line = styled.div.attrs({
  className: 'w-10 ml-6 mb-6 bg-nbt-red md:ml-0',
})`
  height: 0.15rem;
`;

class Header extends React.Component<Props, State> {
  render() {
    const { background, type, title, subtitle, showRedLine } = this.props;

    return (
      <Container background={background}>
        <ContentWrapper>
          {showRedLine && <Line />}
          <Title>
            {type === 'big' ? (
              <H1Hero>{title}</H1Hero>
            ) : (
              <H2Heading>{title}</H2Heading>
            )}
          </Title>
          {subtitle && (
            <Subtitle>
              <Body>{subtitle}</Body>
            </Subtitle>
          )}
        </ContentWrapper>
      </Container>
    );
  }
}

export default Header;

import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.p.attrs({
  className:
  'font-primary font-normal leading-tight md:text-nbt-body-large md:leading-nbt-body',
})``;

const TextBodyLarge = ({ children, className }: Props) => (
  <Text className={className}>{children}</Text>
);

export default TextBodyLarge;

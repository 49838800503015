// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { unescape } from 'lodash';
import { H2Heading, Body, Info, Button } from '../elements';
import { get } from 'lodash';
import OptimizedImage from './optimizedImage';

type Props = {
  image: string,
  title: string,
  textBody: string,
  avatarProfile?: {
    image: string,
    textBody: string,
    textInfo: string,
  },
  button: {
    text: string,
    link: string,
  },
  imageSide: 'left' | 'right',
};

type State = {};

const RowWrapper = styled.div.attrs(({ imageSide }) => ({
  className: `max-w-screen-xl mx-auto flex bg-nbt-anthrazit md:bg-nbt-gray ${
    imageSide === 'right'
      ? 'flex-col md:flex-row-reverse'
      : 'flex-col md:flex-row'
  }`,
}))``;

const PictureContainer = styled.div.attrs({
  className: 'md:w-5/12',
})`
  min-width: 300px;
`;

const Picture = styled(OptimizedImage).attrs({
  className: 'h-64 w-full object-cover md:h-full',
})``;

const ContentContainer = styled.div.attrs({
  className:
    'flex flex-col justify-center max-w-2xl px-8 pt-8 pb-12 md:pr-16 lg:p-16 lg:pl-24 overflow-hidden',
})``;

const Title = styled(H2Heading).attrs({
  className:
    'font-europa text-white md:text-nbt-anthrazit font-bold text-3xl xl:text-4xl leading-none',
})``;

const TextBody = styled(Body).attrs({
  className:
    'text-white md:text-nbt-anthrazit leading-relaxed lg:mb-12 lg:mt-8 my-3 xl:text-lg',
})``;

const ContentFooter = styled.div.attrs({
  className: 'flex flex-col lg:flex-row',
})``;

const Person = styled.div.attrs({
  className: 'flex flex-row items-center py-3 lg:mr-8',
})``;

const Headshot = styled(OptimizedImage).attrs({
  className: 'w-10 h-10 rounded-full',
})``;

const PersonDetails = styled.div.attrs({
  className: '',
})``;

const Name = styled.p.attrs({
  className: ' text-white md:text-nbt-anthrazit text-xs ml-3',
})``;

const Role = styled.p.attrs({
  className: 'text-white md:text-nbt-anthrazit text-xs ml-3',
})``;

const ButtonWrapper = styled.div.attrs({ className: 'py-3' })``;

class CTAPhoto extends React.Component<Props, State> {
  render() {
    const {
      image,
      avatarProfile,
      button,
      title,
      textBody,
      imageSide,
    } = this.props;

    return (
      <RowWrapper imageSide={imageSide}>
        <PictureContainer>
          <Picture src={image} />
        </PictureContainer>
        <ContentContainer>
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <Title>{title}</Title>
            <TextBody>{textBody}</TextBody>
            <ContentFooter>
              {avatarProfile && avatarProfile['image'] && (
                <Person>
                  <Headshot src={avatarProfile['image']} sizes="2.5rem" />
                  <PersonDetails>
                    <Name>
                      <Info>{avatarProfile['textBody']}</Info>
                    </Name>
                    <Role>
                      <Info>{unescape(avatarProfile['textInfo'])}</Info>
                    </Role>
                  </PersonDetails>
                </Person>
              )}
              <ButtonWrapper>
                <Button type="responsive-style" to={get(button, 'link')}>
                  {get(button, 'text')}
                </Button>
              </ButtonWrapper>
            </ContentFooter>
          </ScrollAnimation>
        </ContentContainer>
      </RowWrapper>
    );
  }
}

export default CTAPhoto;

import React from 'react';
import styled from 'styled-components';
import { H2Heading, Body } from '../elements';
import OptimizedImage from './optimizedImage';

type Props = {
  image: string,
  title: string,
  textBody: string,
  background: 'white' | 'gray',
  imageSide: 'left' | 'right',
};

type State = {};

const Container = styled.div.attrs(({ background }) => ({
  className: background === 'gray' ? 'bg-nbt-gray' : 'bg-white',
}))``;

const RowWrapper = styled.div.attrs(({ imageSide }) => ({
  className: `flex py-10 mt-2 md:my-12 lg:my-16 md:max-w-6xl mx-auto ${
    imageSide === 'right'
      ? 'flex-col md:flex-row-reverse'
      : 'flex-col md:flex-row'
  }`,
}))``;

// picture section
const ColumnRight = styled.div.attrs({
  className: 'flex flex-1 md:w-2/3 -my-4 justify-center',
})``;

const Picture = styled(OptimizedImage).attrs({
  className: 'w-full h-full object-cover',
})``;

// content section
const ColumnLeft = styled.div.attrs({
  className: 'flex flex-1 my-3 md:my-10 md:mx-12 max-w-lg justify-center',
})``;

const Content = styled.div.attrs({
  className: 'flex mx-5 mt-5 flex-col justify-center md:mx-0 md:mt-0',
})``;

const Title = styled(H2Heading).attrs({
  className:
    'text-white text-nbt-anthrazit font-bold xl:text-4xl leading-none max-w-2xs',
})``;

const Description = styled(Body).attrs({
  className:
  'text-nbt-anthrazit lg:mt-5 max-w-2xs mt-3',
})``;


class ProductSingle extends React.Component<Props, State> {
  render() {
    const { image, title, textBody, background, imageSide } = this.props;

    return (
      <Container background={background}>
        <RowWrapper imageSide={imageSide}>
          <ColumnRight>
            <Picture src={image} />
          </ColumnRight>
          <ColumnLeft>
            <Content>
              <Title>{title}</Title>
              <Description>{textBody}</Description>
            </Content>
          </ColumnLeft>
        </RowWrapper>
      </Container>
    );
  }
}

export default ProductSingle;

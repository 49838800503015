// @flow
import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  FaTwitter,
  FaFacebook,
  FaLinkedinIn,
  FaHome,
  FaExternalLinkAlt,
} from 'react-icons/fa';
import { H2Heading, Button } from '../elements';
import Link from '../utils/link';

type Props = {
  type: 'light' | 'dark',
  reverse: boolean,
  title: string,
  button: {
    text: string,
    link?: string,
  },
  iconGroup?: [{ social: string, link: string }],
};

type State = {};

const BannerWrapper = styled.div.attrs(({ type }) => ({
  className: `flex flex-col px-6 ${
    type === 'light' ? 'bg-nbt-gray' : 'bg-nbt-anthrazit'
  } py-12`,
}))``;

const Row = styled.div.attrs(({ reverse }) => ({
  className: `w-full max-w-screen-xl mx-auto flex flex-col ${
    reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
  } items-center justify-between`,
}))``;

const Title = styled.div.attrs(({ type }) => ({
  className: `leading-tight text-center font-europa font-bold text-4xl w-full text-white lg:leading-relaxed ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  }`,
}))``;

const Divider = styled.div.attrs(({ type }) => ({
  className: `w-full max-w-screen-xl mx-auto hidden lg:block my-8 border-t ${
    type === 'light' ? 'border-nbt-anthrazit' : 'border-white'
  }`,
}))``;

const ColumnLeft = styled.div.attrs({ className: 'lg:mr-10' })``;

const ColumnRight = styled.div.attrs({ className: 'pt-5 pb-10' })``;

const RowIcons = styled.div.attrs({
  className:
    'w-full max-w-screen-xl mx-auto flex flex-row justify-center lg:px-10 lg:justify-end',
})``;

const SocialIcons = styled.div.attrs({
  className: ' flex flex-row',
})``;

const TwitterIcon = styled(FaTwitter).attrs(({ type }) => ({
  className: `my-1 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  } ml-8`,
  size: '1.5em',
}))``;

const FacebookIcon = styled(FaFacebook).attrs(({ type }) => ({
  className: `my-1 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  } ml-8`,
  size: '1.5em',
}))``;

const LinkedinIcon = styled(FaLinkedinIn).attrs(({ type }) => ({
  className: `my-1 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  } ml-8`,
  size: '1.5em',
}))``;

const WebsiteIcon = styled(FaHome).attrs(({ type }) => ({
  className: `my-1 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  } ml-8`,
  size: '1.5em',
}))``;

const OtherIcon = styled(FaExternalLinkAlt).attrs(({ type }) => ({
  className: `my-1 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  } ml-8`,
  size: '1.5em',
}))``;

class CTA extends React.Component<Props, State> {
  render() {
    const { type, reverse, title, button, iconGroup } = this.props;

    return (
      <BannerWrapper type={type}>
        <Row reverse={reverse}>
          <ColumnLeft>
            <Title type={type}>
              <H2Heading>{title}</H2Heading>
            </Title>
          </ColumnLeft>
          <ColumnRight>
            <Button
              to={get(button, 'link')}
              shadow
              type={type === 'light' ? 'dark' : 'colorful'}
            >
              {get(button, 'text')}
            </Button>
          </ColumnRight>
        </Row>
        <Divider type={type} />
        <RowIcons>
          <SocialIcons>
            {iconGroup &&
              iconGroup.map(({ social, link }) => (
                <Link key={social} to={link}>
                  {
                    {
                      twitter: <TwitterIcon type={type} />,
                      facebook: <FacebookIcon type={type} />,
                      linkedin: <LinkedinIcon type={type} />,
                      website: <WebsiteIcon type={type} />,
                      other: <OtherIcon type={type} />,
                    }[social]
                  }
                </Link>
              ))}
          </SocialIcons>
        </RowIcons>
      </BannerWrapper>
    );
  }
}

export default CTA;

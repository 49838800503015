import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.p.attrs({
  className:
    'font-nimbus font-normal text-nbt-info leading-nbt-info md:text-nbt-body md:leading-nbt-body',
})``;

const TextBody = ({ children, className }: Props) => (
  <Text className={className}>{children}</Text>
);

export default TextBody;

// @flow
import React from 'react';
import styled from 'styled-components';
import { get, unescape } from 'lodash';
import { Info, Body, Button } from '../elements';
import OptimizedImage from './optimizedImage';

type Props = {
  assets: [
    {
      image: string,
      title: string,
      description: string,
      button?: {
        button_text: string,
        button_link?: string,
      },
    }
  ],
};

type State = {};

const Container = styled.div.attrs({ className: 'px-6' })``;

const Wrapper = styled.div.attrs({
  className:
    'hidden bg-white py-10 max-w-screen-xl mx-auto md:block overflow-hidden',
})``;

const Row = styled.div.attrs({
  className: 'md:-mx-8 flex flex-col flex-wrap justify-start lg:flex-row',
})``;

const Header = styled.div.attrs({
  className:
    'flex flex-row justify-between py-3 text-sm border-b text-nbt-anthrazit',
})``;

const Column = styled.div.attrs({
  className: 'py-10 px-8 lg:w-1/2',
})`
  box-sizing: border-box;
`;

const Photo = styled(OptimizedImage).attrs({
  className: 'h-56 w-full object-cover',
})``;

const TextBody = styled.p.attrs({
  className:
    'font-europa mt-3 mb-8 lg:my-0 font-bold text-nbt-anthrazit text-lg leading-snug',
})``;

const Description = styled.p.attrs({
  className: 'py-3',
})``;

/* MOBILE : start */

const SliderSmallScreen = styled.div.attrs({
  className: 'siema w-full md:hidden',
})``;

const SlideSmallScreen = styled.div.attrs({
  className: 'w-full h-full flex-shrink-0',
})``;

const RowBorderSmallScreen = styled.div.attrs({
  className: 'my-12 bg-white border border-gray-200',
})`
  box-shadow: 0 15px 25px -8px rgba(25, 25, 60, 0.08);
`;

const RowSmallScreen = styled.div.attrs({
  className: 'pb-8 flex flex-col',
})``;

const ColumnSmallScreen = styled.div.attrs({
  className: 'mx-5 md:mx-8',
})``;

const ThumbnailContainerSmallScreen = styled.div.attrs({
  className: '',
})``;

const ThumbnailSmallScreen = styled(OptimizedImage).attrs({
  className: 'h-56 w-full object-cover',
})``;

class CTAAsset extends React.Component<Props, State> {
  render() {
    const { assets } = this.props;
    return (
      <Container>
        <Wrapper>
          <Row>
            {assets &&
              assets.map(({ image, title, description, button }, index) => (
                <Column key={index}>
                  <Photo src={image} />
                  <Header>
                    <Info>{title}</Info>
                  </Header>
                  <TextBody>
                    <Body>
                      <Description>{unescape(description)}</Description>
                    </Body>
                  </TextBody>
                  <Button to={get(button, 'button_link')} type="dark" shadow>
                    {get(button, 'button_text')}
                  </Button>
                </Column>
              ))}
          </Row>
        </Wrapper>
        {/* MOBILE : start */}
        <SliderSmallScreen>
          {assets &&
            assets.map(({ image, title, description, button }, index) => (
              <SlideSmallScreen key={index}>
                <RowBorderSmallScreen>
                  <RowSmallScreen>
                    <ThumbnailContainerSmallScreen>
                      <ThumbnailSmallScreen src={image} />
                    </ThumbnailContainerSmallScreen>
                    <ColumnSmallScreen>
                      <Header>
                        <Info>{title}</Info>
                      </Header>
                      <TextBody>{unescape(description)}</TextBody>
                      <Button to={get(button, 'button_link')}>
                        {get(button, 'button_text')}
                      </Button>
                    </ColumnSmallScreen>
                  </RowSmallScreen>
                </RowBorderSmallScreen>
              </SlideSmallScreen>
            ))}
        </SliderSmallScreen>
        {/* MOBILE :  end */}
      </Container>
    );
  }
}

export default CTAAsset;

import React from 'react';
import styled from 'styled-components';
import { Info, H4Heading } from '../elements';

type Props = {
  items: [{ title: string, subtitle: string, textBody: string }],
};

type State = {};

const Container = styled.div.attrs({
  className: 'bg-nbt-gray py-10',
})``;

const Row = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto flex flex-col',
})``;

/** Milestones */

const Timeline = styled.div.attrs({ className: 'relative' })`
  &::after {
    content: '';
    position: absolute;
    width: 1px;
    background-color: #3a3f50;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

    @media (max-width: 767px) {
      left: 10%;
      display: none;
    }
  }
`;

const Item = styled.div.attrs({
  className: 'relative px-12 my-10 md:my-0 md:px-20 w-full md:w-1/2',
})`
  @media (max-width: 767px) {
    left: 0;
    &:after {
      display: none;
      left: -11px;
    }
  }

  ${({ location }) => (location === 'left' ? 'left: 50%;' : 'left: 0;')}

  &:after {
    ${({ location }) => (location === 'left' ? 'left: -11px;' : 'right: -5px;')}
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: white;
    border: 1px solid #3a3f50;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
`;

const ItemContent = styled.div.attrs({ className: 'px-5 py-4 bg-gray-100' })`
  border-radius: 6px;
  border: solid 1px #b8c1cb;
`;

const Title = styled(H4Heading).attrs({
  className: 'text-nbt-anthrazit text-left',
})``;

const Subtitle = styled.div.attrs({ className: 'text-nbt-anthrazit mb-5' })``;

const BodyText = styled.div.attrs({ className: 'text-nbt-dark-gray' })``;

const FONT_TO_SHOW = 'font-nimbus';

class MilestonesDouble extends React.Component<Props, State> {
  static defaultProps = {
    items: [],
  };

  render() {
    const { items } = this.props;

    return (
      <Container>
        <Row>
          <Timeline>
            {items.map(({ title, textBody, subtitle }, index) => (
              <Item key={title} location={index % 2 ? 'left' : 'right'}>
                <ItemContent>
                  <Title>{title}</Title>
                  <Subtitle>
                    <Info>{subtitle}</Info>
                  </Subtitle>
                  <BodyText>
                    <Info className={FONT_TO_SHOW}>{textBody}</Info>
                  </BodyText>
                </ItemContent>
              </Item>
            ))}
          </Timeline>
        </Row>
      </Container>
    );
  }
}

export default MilestonesDouble;

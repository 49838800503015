import React from 'react';
import styled from 'styled-components';
import { H4Heading } from '../elements';
import Link from '../utils/link';

type Props = {
  sections: [
    {
      title: string,
      link: string,
    }
  ],
};

type State = {};

const Container = styled.div.attrs({
  className: 'relative max-w-screen-xl mx-auto mb-12 px-6',
})`
  box-sizing: content-box;
`;

const NavPanel = styled.ul.attrs({
  className: 'lg:absolute lg:ml-24 lg:pt-12',
})``;

const StyledLink = styled(Link).attrs({
  className: 'py-2 block cursor-pointer text-nbt-anthrazit border-none',
})`
  &:hover,
  &.active {
    opacity: 1;
    color: #d95c4b;
  }
`;

class VerticalTabMenu extends React.Component<Props, State> {
  render() {
    const { sections } = this.props;

    return (
      <Container>
        <NavPanel>
          {sections &&
            sections.map(({ submenutitle, linkToPage }, index) => (
              <H4Heading key={index}>
                <StyledLink to={linkToPage} activeClassName="active">
                  {submenutitle}
                </StyledLink>
              </H4Heading>
            ))}
        </NavPanel>
      </Container>
    );
  }
}

export default VerticalTabMenu;

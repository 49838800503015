// @flow
import React from 'react';
import styled from 'styled-components';
import { Info, Body } from '../elements';

type Props = {
  header: string,
  sections: [
    {
      title: string,
      textBody: string,
    },
  ],
};

type State = {};

const Container = styled.div.attrs({ className: 'px-6' })``;

const Wrapper = styled.div.attrs({
  className: 'bg-white py-10 max-w-screen-xl mx-auto overflow-hidden',
})``;

const Row = styled.div.attrs({
  className: 'flex flex-col flex-wrap justify-start lg:flex-row lg:-mx-8',
})``;

const Header = styled.div.attrs({
  className:
    'hidden md:flex flex-row justify-between pb-3 text-sm border-b border-gray-700',
})``;

const HeaderText = styled.div.attrs({
  className: 'text-nbt-anthrazit',
})``;

const HeaderCount = styled.div.attrs({
  className: 'text-nbt-anthrazit',
})``;

const Column = styled.div.attrs({
  className:
    'py-5 md:py-10 px-8 lg:w-1/2 flex flex-col items-start md:items-center',
})`
  box-sizing: border-box;
`;

const Title = styled.p.attrs({
  className: 'pt-2 pb-3 text-nbt-anthrazit',
})``;

const TextBody = styled.p.attrs({
  className: 'font-europa font-bold text-nbt-anthrazit text-lg leading-snug',
})``;

const RowsBorderSmallScreen = styled.div.attrs({
  className: 'border border-gray-200 lg:border-0',
})``;

class TextGrid extends React.Component<Props, State> {
  render() {
    const { sections, header } = this.props;

    return (
      <Container>
        <Wrapper>
          <Header>
            <HeaderText>
              <Info>{header}</Info>
            </HeaderText>
            <HeaderCount>
              <Info>{sections && sections.length}</Info>
            </HeaderCount>
          </Header>
          <RowsBorderSmallScreen>
            <Row>
              {sections &&
                sections.map(({ title, textBody }, index) => (
                  <Column key={index}>
                    <Title>
                      <Body>{title}</Body>
                    </Title>
                    <TextBody>
                      <Info>{textBody}</Info>
                    </TextBody>
                  </Column>
                ))}
            </Row>
          </RowsBorderSmallScreen>
        </Wrapper>
      </Container>
    );
  }
}

export default TextGrid;

import React from 'react';
import styled from 'styled-components';

type Props = {
  className: string,
  children: React.Node,
  size?: 'small' | 'big',
};

const Element = styled.p.attrs({
  className:
    'font-europa font-normal text-nbt-info leading-nbt-info md:text-nbt-button-text md:leading-nbt-button-text',
})``;

class ButtonText extends React.Component<Props> {
  render() {
    return (
      <Element className={this.props.className}>{this.props.children}</Element>
    );
  }
}

export default ButtonText;

// @flow
import React from 'react';
import styled from 'styled-components';
import type { RichText as RichTextType } from '../utils/types';
import { isRichText, sanitizeRichText } from '../utils/types';
import { RichText } from 'prismic-reactjs';
import { get } from 'lodash';
import Link from '../utils/link';

type Props = {
  content: [{
    leftCol: RichTextType,
    button: {
      text: string,
      link: string
    }
  }],
};

type State = {};

const DistanceToEdge = styled.section.attrs({
  className: 'px-6 pb-16',
})`
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 4rem;
    .table-div {
      border-bottom-width: 2px;
      .row:last-child {
        border: 0 none;
      }
    }
  }
`;

const MaxWidth = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto',
})``;

const Table = styled.div.attrs({
  className: 'table-div border-gray-600 border-t-2 lg:pt-0',
})`
  @media (min-width: 1024px) {
    margin-left: 28rem;
  }
`;

const Row = styled.div.attrs({
  className:
    'row py-5 border-dashed border-gray-300 flex justify-between lg:border-t lg:border-b',
})``;

const Title = styled.div.attrs({
  className: 'text-nbt-anthrazit w-2/3',
})``;

const Content = styled.a.attrs({
  className: 'text-nbt-anthrazit ml-6 lg:pb-0',
})``;

const Button = styled(Link).attrs({
  className: `flex items-center justify-center rounded cursor-pointer `,
})`
  border: solid 1px #3a3f50;
`;

const ButtonText = styled.div.attrs({
  className: `text-nbt-anthrazit font-nimbus font-normal px-4 text-nbt-info`,
})``;

class SubPageTable2ColWithBtn extends React.Component<Props, State> {
  render() {
    const { content } = this.props;

    return (
      <DistanceToEdge>
        <MaxWidth>
          <Table>
            {content &&
              content.map(({ leftCol, button }, index) => (
                <Row key={index}>
                  { isRichText(leftCol) && <Title>{RichText.render(sanitizeRichText(leftCol))}</Title> }
                  <Content>
                    <Button to={get(button, 'link')}>
                      <ButtonText>{get(button, 'text')}</ButtonText>
                    </Button>
                  </Content>
                </Row>
              ))}
          </Table>
        </MaxWidth>
      </DistanceToEdge>
    );
  }
}

export default SubPageTable2ColWithBtn;

import React from 'react';
import styled from 'styled-components';
import { TiArrowRight } from 'react-icons/ti';

type Props = {
  type?: 'colorful' | 'dark' | 'red' | 'responsive-style',
};

const Arrow = styled(TiArrowRight).attrs({
  size: '1.7em',
})``;

class ButtonArrow extends React.Component<Props> {
  render() {
    const classNames = ['ml-2'];
    const type = this.props.type;
    switch (type) {
      case 'colorful':
        classNames.push('text-nbt-green');
        break;
      case 'red':
        classNames.push('text-nbt-red');
        break;
      case 'responsive-style':
        classNames.push('text-nbt-red md:text-nbt-green');
        break;
      default:
        break;
    }
    return <Arrow className={classNames.join(' ')} />;
  }
}

export default ButtonArrow;

// @flow
import React from 'react';
import styled from 'styled-components';
import Siema from 'siema';
import SliderDots from '../elements/sliderDots';
import type { Props as articleExcerptProps } from '../utils/types';
import Card from './card';

const shadowSpread = '25px';

type Props = {
  articles: [
    articleExcerptProps
  ],
};

type State = {
  currentSlide: number
};

const Wrapper = styled.div.attrs({
  className: 'hidden bg-white px-6 md:block',
})``;

const MaxWidth = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto overflow-hidden',
})``;

const Row = styled.div.attrs({
  className: '-mx-5 flex flex-col flex-wrap justify-start lg:flex-row',
})``;

const Column = styled.div.attrs({
  className: 'py-12 px-5 lg:w-1/3',
})`
  box-sizing: border-box;
`;

/* MOBILE : start */

const WrapperSmallScreen = styled.div.attrs({
  className: 'md:hidden mt-6 mb-10',
})``;

const SliderSmallScreen = styled.div`
padding-bottom: ${shadowSpread};
margin-bottom: -${shadowSpread};
`;

const ColumnSmallScreen = styled.div.attrs({
  className: 'mx-6'
})`
  box-shadow: 0 15px ${shadowSpread} -8px rgba(25, 25, 60, 0.08);
`;

/* MOBILE : end */

class Article3Column extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }

  state = {
    currentSlide: 0,
  };

  componentDidMount() {
    try {
      const carousel = new Siema({
        selector: this.carouselRef.current,
        perPage: 1,
        onChange: () => {
          this.setState({
            currentSlide: carousel.currentSlide,
          });
        },
      });
    } catch (e) {
      /* Otherwise throws error while testing */
      return;
    }
  }
//
  render() {
    const { articles } = this.props;

    return (
      <>
        <Wrapper>
          <MaxWidth>
            <Row>
              {articles &&
              articles.map(({ link, date, textBody, image }, index) => (
                <Column key={index}>
                  <Card link={link} date={date} title={textBody} image={image} />
                </Column>
              ))}
            </Row>
          </MaxWidth>
        </Wrapper>
        {/* MOBILE : start */}
        <WrapperSmallScreen>
          <SliderSmallScreen ref={this.carouselRef}>
            {articles &&
            articles.map(({ link, image, date, textBody }, index) => (
              <ColumnSmallScreen key={index}>
                <Card link={link} date={date} title={textBody} hasBorder image={image} />
              </ColumnSmallScreen>
            ))}
          </SliderSmallScreen>
          <SliderDots currentIndex={this.state.currentSlide} numberOfDots={articles.length} />
          {/* MOBILE :  end */}
        </WrapperSmallScreen>
      </>
    );
  }
}

export default Article3Column;

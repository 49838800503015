import React from 'react';
import styled from 'styled-components';

type Props = {
  className: string,
  children: React.Node,
};

const Text = styled.h2.attrs({
  className:
    'font-europa font-bold text-nbt-h4 leading-nbt-h4 md:text-nbt-h2 md:leading-nbt-h2',
})``;

const H2Heading = ({ className, children }: Props) => (
  <Text className={className}>{children}</Text>
);

export default H2Heading;

import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

type Props = {
  formId: string,
  portalId: string,
};

type State = {};

const HubspotFormContainer = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto px-6',
})`
  box-sizing: content-box;
`;

class HubspotForm extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.hubspotFormRef = React.createRef();
  }

  handleScriptInject({ scriptTags }) {
    const { portalId, formId } = this.props;

    if (scriptTags) {
      const scriptTag = scriptTags[0];
      const target = '#' + this.hubspotFormRef.current.id;
      scriptTag.addEventListener('load', () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: portalId,
            formId: formId,
            target,
            /* onFormSubmit: (form) => {} */
            /* redirectUrl: '[URL]' */
          });
        }
      });
    }
  }

  render() {
    return (
      <>
        <Helmet
          script={[{ src: 'https://js.hsforms.net/forms/v2.js' }]}
          onChangeClientState={(newState, addedTags) =>
            this.handleScriptInject(addedTags)
          }
        />
        <HubspotFormContainer
          id={'hubspot-form-' + this.props.formId}
          ref={this.hubspotFormRef}
        />
      </>
    );
  }
}

export default HubspotForm;

import React from 'react';
import fetch from 'isomorphic-fetch'; // to remove ESlint error for fetch
import { get } from 'lodash';
import CTAList from './CTAList';

type Props = {
  additionalLink?: {
    text: string,
    link: string,
  },
  alwaysShowAdditionalLink?: boolean,
  callToAction?: {
    title?: string,
    textBody?: string,
    button?: {
      text: string,
      link: string,
    },
  },
  companyId: string,
  department?: string,
  htmlElementId?: string,
  maxRows?: number,
};

type State = {
  positions: [],
};

class OpenPositions extends React.Component<Props, State> {
  constructor() {
    super();
    this.state = {
      additionalLink: null,
      positions: [],
    };
  }
  componentDidMount() {
    this.fetchAndUpdatePositions();
  }
  fetchAndUpdatePositions() {
    let url = `https://${this.props.companyId}.recruitee.com/api/offers/`;
    if (this.props.department) {
      url += `?department=${this.props.department}`;
    }
    fetch(url)
      .then((response) => response.json())
      .then((rawData) => {
        const rawPositions = get(rawData, 'offers', []);
        let positions = rawPositions.map((position) => ({
          title: get(position, 'title'),
          darkButton: {
            text: 'View Offer',
            link: get(position, 'careers_url'),
          },
        }));
        const { maxRows } = this.props;
        let additionalLink = this.props.additionalLink;
        if (maxRows) {
          if (positions.length > maxRows) {
            positions = positions.slice(0, maxRows);
          } else if (!this.props.alwaysShowAdditionalLink) {
            additionalLink = null;
          }
        }
        this.setState({
          additionalLink,
          positions,
        });
      });
  }
  render() {
    return (
      <CTAList
        items={this.state.positions}
        additionalLink={this.state.additionalLink}
        callToAction={this.props.callToAction}
        htmlElementId={this.props.htmlElementId}
      />
    );
  }
}

export default OpenPositions;

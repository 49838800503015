import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.h3.attrs({
  className:
    'font-europa font-bold text-nbt-h4 leading-nbt-h4 md:text-nbt-h3 md:leading-nbt-h3',
})``;

const H3Heading = ({ className, children }: Props) => <Text className={className}>{children}</Text>;

export default H3Heading;

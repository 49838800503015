// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { get } from 'lodash';
import { H2Heading, Body, Button } from '../elements';

type Props = {
  type: 'light' | 'dark',
  reverse: boolean,
  title: string,
  textBody: string,
  button: {
    text: string,
    link: string,
  },
};

type State = {};

const Slice = styled(ScrollAnimation).attrs(({ type }) => ({
  animateIn: 'fadeIn',
  animateOnce: 'true',
  className: `slice flex flex-col ${
    type === 'light' ? 'bg-nbt-gray' : 'bg-nbt-anthrazit'
  } items-center px-6 py-12 md:py-10 lg:py-0`,
}))``;

const Row = styled(ScrollAnimation).attrs(({ reverse }) => ({
  animateIn: 'fadeInUp',
  animateOnce: 'true',
  className: `flex flex-col overflow-hidden items-start ${
    reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
  } justify-around lg:items-center border-gray-600 md:py-10`,
}))``;

const Title = styled.div.attrs(({ type }) => ({
  className: `leading-tight font-europa font-bold text-4xl max-w-2xl mr-8 text-white md:leading-relaxed lg:leading-loose ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  }`,
}))``;

const Divider = styled.div.attrs(({ type }) => ({
  className: `hidden lg:block max-w-2xl my-5 border-t ${type} border-nbt-anthrazit`,
}))`
  &:not(.light) {
    border-color: #ffffff;
  }
`;

const Description = styled.div.attrs(({ type }) => ({
  className: `my-8 max-w-xl text-white lg:my-0 ${
    type === 'light' ? 'text-nbt-anthrazit' : 'text-white'
  }`,
}))``;

const ColumnLeft = styled.div.attrs({ className: 'mr-10 w-3/5' })``;

const ColumnRight = styled.div.attrs({ className: 'pr-12' })``;

class CTA extends React.Component<Props, State> {
  render() {
    const { type, reverse, title, textBody, button } = this.props;
    return (
      <Slice type={type}>
        <Row reverse={reverse}>
          <ColumnLeft>
            <Title type={type}>
              <H2Heading>{title}</H2Heading>
            </Title>
            <Divider type={type} />
            <Description type={type}>
              <Body>{textBody}</Body>
            </Description>
          </ColumnLeft>
          <ColumnRight>
            <Button
              to={button.link}
              shadow
              type={type === 'light' ? 'dark' : 'colorful'}
            >
              {get(button, 'text')}
            </Button>
          </ColumnRight>
        </Row>
      </Slice>
    );
  }
}

export default CTA;

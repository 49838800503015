
    import React from 'react';
    import Menu from '../components/menu';
    import { useStaticQuery, graphql } from 'gatsby';

    type Props = {
      onToggleMenu: () => void,
      showMenu: boolean,
    };

    const MenuContainer = (props: Props) => {
      const data = useStaticQuery(graphql`
    query {
      menuJson {
         menu {  logo pictogram menu {  item itemLink } moreMenu {  item itemLink } }
      }
    }`);

      const { menu } = data.menuJson;

      if (!menu) return null;

      return (
        <Menu
          {...menu}
          onToggleMenu={props.onToggleMenu}
          showMenu={props.showMenu}
        />
      );
    };
    export default MenuContainer;
  
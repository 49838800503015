// @flow
import React from 'react';
import styled from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';
import { H1Hero } from '../elements';
import OptimizedImage from './optimizedImage';

type path = String;

type Props = {
  title: string,
  subtitle?: string,
  image: path,
};

type State = {};

const Container = styled.div.attrs(({ hasImage }) => ({
  className: `lg:bg-nbt-gray pt-20 ${hasImage ? '' : 'overflow-hidden'}`,
}))``;

const ContentWrapper = styled.div.attrs({
  className: 'flex flex-col items-start md:items-center',
})``;

const ImageWrapper = styled.div.attrs({
  className: 'max-w-6xl mx-0 md:mx-16 xl:mx-auto',
})``;

const Image = styled(OptimizedImage).attrs({
  className: `object-cover bg-white w-full h-64 mt-20 md:h-auto`,
})`
  box-shadow: 0 23px 33px 0 #e1e3e7;
  @media (min-width: 768px) {
    border-radius: 3em 3em 0 0;
  }
`;

const Title = styled.p.attrs({
  className:
    'text-left mb-3 text-nbt-anthrazit px-6 max-w-xl md:text-center md:px-0',
})``;

const Subtitle = styled.p.attrs({
  className:
    'text-left max-w-4xl mt-3 text-nbt-anthrazit font-europa text-nbt-h4 px-6 md:text-center md:px-0',
})``;

const Line = styled.div.attrs({
  className: 'w-10 ml-6 mb-6 bg-nbt-red md:ml-0',
})`
  height: 0.15rem;
`;

class Hero extends React.Component<Props, State> {
  render() {
    const { title, subtitle, image } = this.props;

    return (
      <Container hasImage={image ? true : false}>
        <ContentWrapper>
          <Line />
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <Title>
              <H1Hero>{title}</H1Hero>
            </Title>
          </ScrollAnimation>
          {subtitle && (
            <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
              <Subtitle>{subtitle}</Subtitle>
            </ScrollAnimation>
          )}
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
            <ImageWrapper>
              <Image src={image} sizes="(min-width: 1280px) 1152px, 100vw" />
            </ImageWrapper>
          </ScrollAnimation>
        </ContentWrapper>
      </Container>
    );
  }
}

export default Hero;

import React from 'react';
import styled from 'styled-components';
import { H4Heading } from '../elements';
import Accordion from './accordion';
import type { RichText } from '../utils/types';
import { get } from 'lodash';

type Props = {
  title: string,
  questions: [{ question: string, answer: RichText }],
};

const Background = styled.div.attrs({ className: 'px-6 md:bg-nbt-gray' })``;

const Section = styled.section.attrs({
  className: 'text-nbt-anthrazit max-w-screen-xl mx-auto py-10',
})``;

const Heading = styled(H4Heading).attrs({ className: 'inline' })``;

const FAQAccordion = styled(Accordion).attrs({ className: 'my-10' })``;

class FAQSection extends React.Component<Props> {
  render() {
    const title = get(this, 'props.title');
    const questions = get(this, 'props.questions', []);
    return (
      <Background>
        <Section>
          {title && <Heading>{title}</Heading>}
          {questions.map((question, index) => (
            <FAQAccordion
              key={index}
              title={question.question}
              text={question.answer}
            />
          ))}
        </Section>
      </Background>
    );
  }
}

export default FAQSection;

import React from 'react';
import styled from 'styled-components';
import Link from '../utils/link';

type Props = {
  arrowColor?: string,
  className?: string,
  children?: React.Node,
  shadow?: boolean,
  to?: string,
  type?: 'colorful' | 'dark' | 'responsive-style' | 'transparent' | 'white',
};

const ButtonElement = styled(Link).attrs({
  className: 'cta-button',
})``;

class Button extends React.Component<Props> {
  render() {
    const { className, shadow, to, type } = this.props;
    let classNames = [];
    if (className && typeof className === 'string') {
      classNames.push(className);
    }
    switch (type) {
      case 'transparent':
        classNames.push('transparent');
        break;
      case 'light-gray':
        classNames.push('light-gray');
        break;
      case 'white':
        classNames.push('white');
        break;
      case 'green':
        classNames.push('green');
        break;
      case 'colorful':
        classNames.push('colorful');
        break;
      case 'responsive-style':
        classNames.push(
          'responsive'
        );
        break;
      case 'dark':
      default:
        classNames.push('dark');
        break;
    }
    if (shadow) {
      classNames.push('shadow');
    }
    return (
      <ButtonElement className={classNames.join(' ')} to={to}>
        {this.props.children}
      </ButtonElement>
    );
  }
}

export default Button;

import H1Hero from './h1Hero';
import TextBody from './textBody';
import TextBodyLarge from './textBodyLarge';
import ButtonArrow from './buttonArrow';
import Button from './button';
import ButtonText from './buttonText';
import H2Heading from './h2Heading';
import H3Heading from './h3heading';
import H4Heading from './h4heading';
import Info from './info';
import RichText from './richText';
import PhotoMask from './PhotoMask';

const Body = TextBody;

export {
  H1Hero,
  Body,
  TextBodyLarge,
  H2Heading,
  Button,
  ButtonArrow,
  ButtonText,
  H3Heading,
  H4Heading,
  Info,
  RichText,
  PhotoMask,
};

import CTAColumn from './CTAColumn';
import CTAPhoto from './CTAPhoto';
import CTA from './CTA';
import Feature3Column from './feature3Column';
import CTAWith2Buttons from './CTAWith2Buttons';
import CarouselTestimonials from './carouselTestimonials';
import Article3Column from './article3Column';
import TextGrid from './textGrid';
import Ventures from './ventures';
import ProductSingle from './productSingle';
import CTASocial from './CTASocial';
import SlideshowProfile from './slideshowProfile';
import Milestones from './milestones';
import Carousel from './carousel';
import CTAList from './CTAList';
import OpenPositions from './openPositions';
import Header from './header';
import BannerMask from './bannerMask';
import CTA2Columns from './CTA2Columns';
import Testimonial from './testimonial';
import Intro from './intro';
import FAQSection from './faqSection';
import HubspotForm from '../components/hubspotForm';
import GridIndex from './gridIndex';
import RichTextColumn from './richTextColumn';
import CTAGrid from './CTAGrid';
import LogoRoll from './logoRoll';
import TableRow from './tableRow';
import Block3Column from './block3Column';
import VerticalTabMenu from './verticalTabMenu';
import SubPageTitleBody from './subPageTitleBody';
import SubPageTable2Col from './subpageTable2Col';
import SubPageTable2ColWithBtn from './subpageTable2ColWithBtn';
import GridPeople from './gridPeople';
import GridPortfolio from './gridPortfolio';
import Divider from './divider';
import CTAAsset from './CTAAsset';
import MilestonesDouble from './milestonesDouble';
import Hero from './hero';
import SectionSeparator from './sectionSeparator';

const COMPONENTS = {
  CTA,
  Intro,
  BannerMask,
  CTA2Columns,
  Testimonial,
  CTAColumn,
  Ventures,
  TextGrid,
  CTAPhoto,
  Article3Column,
  Feature3Column,
  CarouselTestimonials,
  CTAWith2Buttons,
  CTASocial,
  ProductSingle,
  SlideshowProfile,
  Milestones,
  Carousel,
  CTAList,
  OpenPositions,
  Header,
  FAQSection,
  RichTextColumn,
  CTAGrid,
  HubspotForm,
  GridIndex,
  LogoRoll,
  TableRow,
  Block3Column,
  VerticalTabMenu,
  SubPageTitleBody,
  SubPageTable2Col,
  SubPageTable2ColWithBtn,
  GridPeople,
  GridPortfolio,
  Divider,
  CTAAsset,
  MilestonesDouble,
  Hero,
  SectionSeparator
};

export default COMPONENTS;

import React from 'react';
import styled from 'styled-components';
import { times } from 'lodash';

type Props = {
  currentIndex: number,
  numberOfDots: number,
}

const Dots = styled.div.attrs({
  className: 'flex flex-row justify-center mt-8',
})``;

const Dot = styled.div.attrs({
  className: 'w-2 h-2 mx-1 rounded-full',
})`
  background-color: ${({ active }) => (active ? '#3a3f50' : '#b8c1cb')};
`;

class SliderDots extends React.Component<Props> {
  render() {
    const { currentIndex, numberOfDots } = this.props;
    return (
      <Dots>
        {times(numberOfDots).map((index) => (
          <Dot key={index} active={index === currentIndex} />
        ))}
      </Dots>
    );
  }
}

export default SliderDots;

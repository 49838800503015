import React from 'react';
import styled from 'styled-components';

type Props = {
  className?: string,
  children: React.Node,
};

const Text = styled.p.attrs({
  className: 'font-europa font-normal text-nbt-info leading-nbt-info',
})``;

class Info extends React.Component<Props> {
  render() {
    return <Text className={this.props.className}>{this.props.children}</Text>;
  }
}

export default Info;

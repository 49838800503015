import React from 'react';
import styled from 'styled-components';
import { H3Heading, Info } from '../elements';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { times } from 'lodash';
import Siema from 'siema';
import OptimizedImage from './optimizedImage';

/* REFACTOR update this to mandatory, add defaultProps  */
type Props = {
  slides: [
    {
      image: string,
      title: string,
      textBody: string,
    }
  ],
};

type State = {
  currentSlide: number,
};
const BREAKPOINT_MEDIUM = 768;

const Wrapper = styled.div.attrs({
  className: 'mx-auto max-w-screen-xl mb-10',
})``;

const RowWrapper = styled.div.attrs({
  className: 'flex flex-col lg:flex-row justify-center lg:items-center',
})``;

const Row = styled.div.attrs({
  className: 'md:px-6 flex flex-col md:flex-row',
})``;

const ColumnRight = styled.div.attrs({
  className: 'flex flex-1 justify-end',
})``;

const ColumnLeft = styled.div.attrs({
  className: 'flex flex-1 justify-center mb-10 lg:mb-0',
})``;

const Content = styled.div.attrs({
  className: 'flex mx-5 mt-5 flex-col justify-center md:ml-0 md:mt-0',
})``;

const Picture = styled(OptimizedImage).attrs({
  className: 'w-full object-cover',
})`
  height: 26rem;
  object-position: 50% 30%;
  @media (min-width: ${BREAKPOINT_MEDIUM}px) {
    width: 26rem;
  }
`;
const Title = styled(H3Heading).attrs({
  className: 'text-nbt-anthrazit max-w-lg',
})``;

const TextBody = styled(Info).attrs({
  className: 'text-nbt-anthrazit lg:mt-5 max-w-lg mt-3 font-nimbus',
})``;

const ArrowLeftTouchable = styled.div.attrs({
  className: 'hidden lg:block cursor-pointer',
})``;

const ArrowRightTouchable = styled.div.attrs({
  className: 'hidden lg:block cursor-pointer',
})``;

const ChevronLeftIcon = styled(FaChevronLeft).attrs({
  className: 'text-nbt-red',
  size: '2em',
})``;

const ChevronRightIcon = styled(FaChevronRight).attrs({
  className: 'text-nbt-red',
  size: '2em',
})``;

const Slider = styled.div.attrs({
  className: 'w-full lg:mx-auto lg:w-11/12',
})``;

const Dots = styled.div.attrs({
  className: 'flex flex-row justify-center mt-8',
})``;

const Dot = styled.div.attrs({
  className: 'w-2 h-2 mx-1 rounded-full cursor-pointer',
})`
  background-color: ${({ active }) => (active ? '#3a3f50' : '#b8c1cb')};
`;

class SlideshowProfile extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.carouselRef = React.createRef();
  }

  static defaultProps = {
    slides: [],
  };

  state = {
    currentSlide: 0,
  };

  componentDidMount() {
    if (this.props.slides && this.props.slides.length > 1) {
      try {
        // eslint-disable-next-line no-undef
        this.siema = new Siema({
          selector: this.carouselRef.current,
          perPage: 1,
          loop: true,
          onChange: () => {
            this.setState({ currentSlide: this.siema.currentSlide });
          },
        });
      } catch (e) {
        /* Otherwise throws error while testing */
        return;
      }
    }
  }

  render() {
    const { slides } = this.props;

    const { currentSlide } = this.state;

    return (
      <Wrapper>
        <div>
          <RowWrapper>
            {slides && slides.length > 1 && (
              <ArrowLeftTouchable onClick={() => this.siema.prev()}>
                <ChevronLeftIcon />
              </ArrowLeftTouchable>
            )}
            <Slider ref={this.carouselRef}>
              {slides &&
                slides.map(({ title, textBody, image }) => (
                  <Row key={title}>
                    <ColumnLeft>
                      <Content>
                        <Title>{title}</Title>
                        <TextBody>{textBody}</TextBody>
                      </Content>
                    </ColumnLeft>
                    <ColumnRight>
                      <Picture src={image} />
                    </ColumnRight>
                  </Row>
                ))}
            </Slider>
            {slides && slides.length > 1 && (
              <ArrowRightTouchable onClick={() => this.siema.next()}>
                <ChevronRightIcon />
              </ArrowRightTouchable>
            )}
          </RowWrapper>
          <Dots>
            {slides &&
              slides.length > 1 &&
              times(slides.length).map((index) => (
                <Dot
                  key={index}
                  active={index === currentSlide}
                  onClick={() => this.siema.goTo(index)}
                />
              ))}
          </Dots>
        </div>
      </Wrapper>
    );
  }
}

export default SlideshowProfile;

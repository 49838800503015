// @flow
import React from 'react';
import styled from 'styled-components';
import type { RichText as RichTextType } from '../utils/types';
import { isRichText, sanitizeRichText } from '../utils/types';
import { RichText } from 'prismic-reactjs';

type Props = {
  header: string,
  content: [{
    leftCol: RichTextType,
    rightCol: RichTextType
  }],
};

type State = {};

const DistanceToEdge = styled.section.attrs({
  className: 'px-6 pb-16',
})`
  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 4rem;
    .table-div {
      border-bottom-width: 2px;
      .row:last-child {
        border: 0 none;
      }
    }
  }
`;

const MaxWidth = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto',
})``;

const Table = styled.div.attrs({
  className: 'table-div border-gray-600 border-t-2',
})`
  @media (min-width: 1024px) {
    margin-left: 28rem;
  }
`;

const Row = styled.div.attrs({
  className:
    'row py-5 border-dashed border-gray-300 flex justify-start lg:border-t lg:border-b',
})``;

const TableHeader = styled.p.attrs({
  className: 'pt-10 pb-5 text-nbt-red font-bold font-europa text-xl',
})``;

const Title = styled.p.attrs({
  className: 'text-nbt-anthrazit w-24 md:w-32 lg:w-48 xl:w-64 lg:pb-0',
})``;

const Text = styled.div.attrs({
  className: 'text-nbt-anthrazit ml-4 md:ml-32 lg:ml-48 xl:ml-64',
})`
  // Preserve space for empty paragraphs
  p:empty:before {
    content: ' ';
    white-space: pre;
  }
`;

class SubPageTable2Col extends React.Component<Props, State> {
  render() {
    const { header, content } = this.props;

    return (
      <DistanceToEdge>
        <MaxWidth>
          <Table>
            <TableHeader>{header}</TableHeader>
            {content &&
              content.map(({ leftCol, rightCol }) => (
                <Row key={leftCol}>
                  { isRichText(leftCol) && <Title>{RichText.render(sanitizeRichText(leftCol))}</Title> }
                  { isRichText(rightCol) && <Text>{RichText.render(sanitizeRichText(rightCol))}</Text> }
                </Row>
              ))}
          </Table>
        </MaxWidth>
      </DistanceToEdge>
    );
  }
}

export default SubPageTable2Col;

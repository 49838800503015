// @flow
import React from 'react';
import styled from 'styled-components';
import { Info, H4Heading, PhotoMask } from '../elements';

type Props = {
  features: [
    {
      logo: string,
      title: string,
      textBody: string,
    },
  ],
};

type State = {};

const Container = styled.div.attrs({ className: 'px-6 my-10' })``;

const Wrapper = styled.div.attrs({
  className: 'max-w-screen-xl mx-auto hidden bg-white py-5 lg:block',
})``;

const Row = styled.div.attrs({
  className: 'mx-5 flex flex-col flex-nowrap justify-start lg:flex-row lg:justify-around',
})``;

const ColumnWrapper = styled.div.attrs({
  className: 'mx-2 lg:w-1/3',
})``;

const Column = styled.div.attrs({
  className:
    'flex flex-col items-center py-10 px-4 border border-gray-400 rounded',
})`
  box-sizing: border-box;
`;

const Title = styled.p.attrs({
  className: 'mt-5 mb-3 text-nbt-anthrazit',
})``;

const TextBody = styled(Info).attrs({
  className:
    'font-europa text-nbt-anthrazit text-lg leading-loose sm:leading-relaxed text-center',
})``;

/* MOBILE : start */

const SliderSmallScreen = styled.div.attrs({
  className: 'w-full lg:hidden',
})``;

const SlideSmallScreen = styled.div.attrs({
  className: 'w-full h-full flex-shrink-0',
})``;

const RowBorderSmallScreen = styled.div.attrs({
  className: 'my-5 bg-white border border-gray-200',
})`
  box-shadow: 0 15px 25px -8px rgba(25, 25, 60, 0.08);
`;

const RowSmallScreen = styled.div.attrs({
  className: 'mx-8 my-8 flex flex-col text-center',
})``;

/* MOBILE : end */

class Feature3Column extends React.Component<Props, State> {
  render() {
    const { features } = this.props;

    return (
      <Container>
        <Wrapper>
          <Row>
            {features &&
              features.map(({ title, textBody, logo }, index) => (
                <ColumnWrapper key={index}>
                  <Column>
                    <PhotoMask src={logo} />
                    <Title>
                      <H4Heading>{title}</H4Heading>
                    </Title>
                    <TextBody>{textBody}</TextBody>
                  </Column>
                </ColumnWrapper>
              ))}
          </Row>
        </Wrapper>
        {/* MOBILE : start */}
        <SliderSmallScreen>
          {features &&
            features.map(({ logo, title, textBody }, index) => (
              <SlideSmallScreen key={index}>
                <RowBorderSmallScreen>
                  <RowSmallScreen>
                    <PhotoMask src={logo} />
                    <Title>
                      <H4Heading>{title}</H4Heading>
                    </Title>
                    <TextBody>{textBody}</TextBody>
                  </RowSmallScreen>
                </RowBorderSmallScreen>
              </SlideSmallScreen>
            ))}
        </SliderSmallScreen>
        {/* MOBILE :  end */}
      </Container>
    );
  }
}

export default Feature3Column;
